<script setup lang="ts">
import EnvironmentSwitcher from './EnvironmentSwitcher.vue'
import Menu from './Menu.vue'
import { ref } from 'vue'

const drawerVisible = ref(true)
</script>

<template>
  <q-drawer
    side="left"
    behavior="desktop"
    show-if-above
    bordered
    :width="drawerVisible ? 256 : 40"
  >
    <div v-if="drawerVisible">
    <EnvironmentSwitcher></EnvironmentSwitcher>
      <!-- Button to close the drawer -->
      <q-btn
      dense
      round
      absolute
      size="12px"
      style="top: 5px; left: 220px;"
      color="primary"
      icon="chevron_left"
      @click="drawerVisible = false"
      />
    </div>
    <Menu v-if="drawerVisible"></Menu>


    <!-- Button to open the drawer -->
    <div v-else>
          <q-btn
            dense
            round
            absolute
            size="12px"
            style="top: 5px; left: 5px;"
            color="primary"
            icon="chevron_right"
            @click="drawerVisible = true"
          />
    </div>

  </q-drawer>
</template>

<style lang="scss" scoped>
@import '@/styles/app.variables.scss';

</style>
