<script setup lang="ts">
import { ref, Ref, provide, computed } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import ImportFiles from './ImportFiles.vue'

interface Props {
  project: any
  currentDirectory: any
  dataTransferItems?: any
  isEmptyGed: boolean
}

const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits, 'addItemClick', 'editItemClick', 'update:visible'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)
const filesToUpload: Ref<Array<File>> = ref([])

const elementsToUpload: Ref<Array<any>> = ref([])
provide('elementsToUpload', elementsToUpload)

const uploading = ref(false)
const progress1 = ref(0)
const progress2 = ref(0)

async function getFile(fileEntry) {
  try {
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject))
  } catch (err) {
    console.log(err)
  }
}

const dataTransferItems = ref([])
provide('dataTransferItems', dataTransferItems)

if (props.dataTransferItems) {
  dataTransferItems.value = props.dataTransferItems
}

/**
 * Uploads a file or directory (recursively with its items)
 * Returns a list of the nodeIds created
 * @param item
 * @param parent
 */
async function uploadItem(item, parent) {
  if (item.isDirectory) {
    let newDirectory = await userApi.createDocumentTreeDirectory(props.project?.value.id, {
      name: item.name,
      order: 0,
      parentId: parent,
    })
    let nodeIds = [newDirectory.id]

    // Upload each item under the current directory
    let directoryReader = item.createReader()

    let entries = await new Promise((resolve, reject) => {
      directoryReader.readEntries(entries => resolve(entries), error => reject(error));
    });
    // Use Promise.all to upload items in parallel
    await Promise.all(entries.map(async (entry) => {
      let childrenNodeIds = await uploadItem(entry, newDirectory.id);
      nodeIds = nodeIds.concat(childrenNodeIds);
    }));
    return nodeIds
  } else {
    let file = await getFile(item) // Wait until we have the file
    let nodeIds = await userApi.uploadDocumentTreeFiles(props.project?.value.id, [file], parent, onUploadProgress)
    return nodeIds
  }
}

function onUploadProgress(progressEvent) {
  progress1.value = progressEvent.progress
}

async function uploadAllElements() {
  uploading.value = true

  console.log('uploading to', props.currentDirectory)

  try {
    const totalItems = dataTransferItems.value.length;
    let processedItems = 0;
    let nodeIds = [];

    await Promise.all(dataTransferItems.value.map(async (item) => {
      let nodeIdList = await uploadItem(item, props.currentDirectory?.id);
      nodeIds = nodeIds.concat(nodeIdList);

      // Update progress based on the number of processed items
      processedItems++;
      progress2.value = processedItems / totalItems;
    }));

    // Launch batch treatment
    // if (doClassify.value || doExtract.value) {
    //     await userApi.batchTreatment(props.project?.value.id, nodeIds, doClassify.value, doExtract.value)
    // }
    // TODO: Tell parent to freeze
    emit('ok', filesToUpload.value)

  } catch (error) {
    console.error('Error during upload:', error);
    // TODO: Handle inform user
  } finally {
    uploading.value = false;
  }
}

const progressLabel1 = computed(() => {
  ;(progress1.value * 100).toFixed(2) + '%'
})

const progressLabel2 = computed(() => {
  ;(progress2.value * 100).toFixed(2) + '%'
})

// const doExtract = ref(false)
// const doClassify = ref(false)
</script>

<template>
  <q-dialog ref="dialogRef" no-backdrop-dismiss no-esc-dismiss>
    <BaseDialog
      title="Importer des fichiers"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <ImportFiles></ImportFiles>
        <!-- <BaseCheckbox v-model="doExtract" label="Résumer les documents et extraire les champs"/>
        <BaseCheckbox v-model="doClassify" :disable="!props.isEmptyGed" label="Ranger automatiquement les documents dans une arborescence type">
          <q-tooltip v-if="!props.isEmptyGed">Le rangement à l'import ne peut se faire que sur une GED préalablement vide.</q-tooltip>
        </BaseCheckbox> -->

        <div v-if="uploading">
          <div style="font-weight: 700; margin-top: 10px">Progression :</div>
          Fichier en cours :
          <q-linear-progress size="25px" :value="progress1" color="primary">
            <div class="absolute-full flex flex-center">
              <!-- <q-badge color="white" text-color="accent" /> -->
            </div>
          </q-linear-progress>

          Total :
          <q-linear-progress size="25px" :value="progress2" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
              <!-- <q-badge color="white" text-color="accent" /> -->
            </div>
          </q-linear-progress>
        </div>
      </template>

      <template #actions>
          <q-btn color="primary" @click="uploadAllElements" flat label="Importer" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
