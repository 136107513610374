<script setup lang="ts">
import { ref, provide, watch, Ref, inject, onBeforeMount } from 'vue'
import { QTableProps, Dialog } from 'quasar'
import { Node } from '@/models/database/Node'
import { Question } from '@/models/survey/Question'
import { Database } from '@/models/database/Database'
import draggable from 'vuedraggable'
import Editor from '@tinymce/tinymce-vue'
import BaseInput from '../base/BaseInput.vue'
import BaseCheckbox from '../base/BaseCheckbox.vue'
import BaseSelect from '../base/BaseSelect.vue'
import BaseDatePicker from '../base/BaseDatePicker.vue'
import BaseNumberInput from '../base/BaseNumberInput.vue'
import SurveyArrayFormDialog from './dialog/SurveyArrayFormDialog.vue'
import SurveyMultiArrayFormDialog from './dialog/SurveyMultiArrayFormDialog.vue'
import { Survey } from '@/models/survey/Survey'

interface Props {
  question: Question
  completeQuestion: Question | undefined
  isFromModal?: boolean
  survey: Survey
}

const props = withDefaults(defineProps<Props>(), {
  isFromModal: false,
  completeQuestion: undefined,
})

const databaseObject: Ref<Array<Node | null>> = inject('databaseObject')
const databaseChildObject: Ref<Array<any>> = inject('databaseChildObject', ref([]))

const databaseForInsert: Ref<Database> = inject('databaseForInsert')

const arrayForm = ref([])
const arrayValue = ref({})
const arrayIndexSelected = ref(0)
const multiArrayIndexSelected = ref(0)
const booleanSelectValues = [
  {
    label: 'Oui',
    value: true,
  },
  {
    label: 'Non',
    value: false,
  },
]

const multisignColumns: QTableProps['columns'] = [
  { name: 'action', label: '', field: 'action', align: 'left' },
  { name: 'email', label: 'Adresse mail', field: 'email', align: 'left' },
  { name: 'firstname', label: 'Prénom', field: 'firstname', align: 'left' },
  { name: 'lastname', label: 'Nom', field: 'lastname', align: 'left' },
]
const arrayColumns = ref([])

const isArrayFormDialogVisible = ref(false)
provide('isArrayFormDialogVisible', isArrayFormDialogVisible)

onBeforeMount(() => {
  if (props.question.type === 'multisign') {
    if (props.question.answer.value === null) {
      props.question.answer.value = [{ email: '', firstName: '', lastName: '' }]
    }
  }

  if (props.question.type === 'array') {
    props.question.answer.value = props.question.answer.value === null ? [] : props.question.answer.value
    arrayColumns.value.push({
      name: 'action',
      label: '',
      field: 'action',
      align: 'left',
    })
    props.question.options.forEach((option) => {
      arrayColumns.value.push({
        name: option.id,
        label: option.label,
        field: option.id,
        align: 'left',
      })
    })
  }

  if (props.question.type === 'multi') {
    props.question.answer.value = Array.isArray(props.question.answer.value) ? props.question.answer.value : []
  }

  if (props.question.type === 'unisign') {
    if (props.question.answer.value === null) {
      props.question.answer.value = { email: '', firstName: '', lastName: '' }
    }
  }

  if (props.question.type === 'multiarray') {
    if (props.question.answer.value === null) {
      props.question.answer.value = []
    }
  }
})

function addDataWithMappingData() {
  try {
    console.log('completeQuestion - props.question.label', props.completeQuestion, props.question.label)
    if (props.completeQuestion && props.completeQuestion.type === 'object') {
      const mappings = props.completeQuestion.databaseObjectModelFieldsMapping[0].data
      console.log('mappings', mappings)
      mappings.forEach((mapping) => {
        if (mapping.variableFieldName !== undefined && mapping.variableFieldName === props.question.label) {
          databaseObject.value.fields.forEach((databaseObjectField) => {
            if (
              databaseObjectField.label === mapping.databaseObjectModelFieldName ||
              (databaseObjectField.label.includes('Représentant légal') &&
                mapping.databaseObjectModelFieldName.includes('Représentant légal'))
            ) {
              console.log(
                'found mapping',
                databaseObjectField.label,
                databaseObjectField.value,
                props.question.answer.value,
              )
              props.question.answer.value = databaseObjectField.value
            }
            if (
              databaseObjectField.label.includes('Adresse') &&
              (mapping.databaseObjectModelFieldName.endsWith('Adresse') ||
                mapping.databaseObjectModelFieldName === 'Adresse du siège social' ||
                mapping.databaseObjectModelFieldName === 'Siège social')
            ) {
              console.log("found Adresse field")
              const objectField = databaseObjectField
              let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
              if (objectField.value.fields[1].value !== undefined) {
                address += ' ' + objectField.value.fields[1].value
              }
              if (objectField.value.fields[2].value !== undefined) {
                address += ' ' + objectField.value.fields[2].value
              }
              if (objectField.value.fields[3].value !== undefined) {
                address += ' ' + objectField.value.fields[3].value
              }
              if (objectField.value.fields[4].value !== undefined) {
                address += ' ' + objectField.value.fields[4].value
              }
              if (objectField.value.fields[5].value !== undefined) {
                address += ' ' + objectField.value.fields[5].value
              }
              props.question.answer.value = address
            }

            if (
              databaseObjectField.label === 'Représentant légal' &&
              mapping.databaseObjectModelFieldName.includes('Représentant légal')
            ) {
              console.log('représentant légal', databaseObjectField)
              if (databaseObjectField.value !== undefined) {
                console.log('has a link')
                console.log('database', databaseForInsert.value)
                const linkedNode = findObjectById(databaseForInsert.value.nodes, databaseObjectField.value)
                console.log('linked node', linkedNode)
                if (linkedNode !== undefined) {
                  if (mapping.databaseObjectModelFieldName === 'Représentant légal/Prénom') {
                    props.question.answer.value = linkedNode.fields[1].value
                  }
                  if (mapping.databaseObjectModelFieldName === 'Représentant légal/Nom') {
                    props.question.answer.value = linkedNode.fields[2].value
                  }
                  if (mapping.databaseObjectModelFieldName === 'Représentant légal/Label') {
                    props.question.answer.value =
                      linkedNode.fields[0].value + ' ' + linkedNode.fields[1].value + ' ' + linkedNode.fields[2].value
                  }
                }
              }
            }
          })
        }
      })
    }
  } catch (e) {
    console.log(e)
  }
}

function findObjectById(nodes, objectId) {
  let result = undefined
  nodes.forEach((node) => {
    if (node.id === objectId) {
      result = node
    }
    const childResult = findObjectById(node.children, objectId)
    if (childResult !== undefined) {
      result = childResult
    }
  })
  return result
}

function fillArrayRowWithMapping(object, row) {
  try {
    console.log('filling array - completeQuestion', props.completeQuestion, object, row)
    if (props.completeQuestion && props.completeQuestion.type === 'array') {
      const mappings = props.completeQuestion.databaseObjectModelFieldsMapping[0].data
      console.log('mappings', mappings)
      mappings.forEach((mapping) => {
        // object.fields.forEach((field) => {
        console.log('mapping', mapping)
        const opt = props.question.options.find((option) => {
          return option.label === mapping.variableFieldName
        })

        if (opt) {
          console.log('found', opt)
          object.fields.forEach((field) => {
            if (
              field.label === mapping.databaseObjectModelFieldName ||
              (field.label === 'Capital social' && mapping.databaseObjectModelFieldName === 'Capital Social')
            ) {
              console.log("normal value", field.value)
              row[opt.id] = field.value.toString()
            }

            if (
              field.label.includes('Adresse') &&
              (mapping.databaseObjectModelFieldName.endsWith('Adresse') ||
                mapping.databaseObjectModelFieldName === 'Adresse du siège social')
            ) {
              const objectField = field
              let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
              if (objectField.value.fields[1].value !== undefined) {
                address += ' ' + objectField.value.fields[1].value
              }
              if (objectField.value.fields[2].value !== undefined) {
                address += ' ' + objectField.value.fields[2].value
              }
              if (objectField.value.fields[3].value !== undefined) {
                address += ' ' + objectField.value.fields[3].value
              }
              if (objectField.value.fields[4].value !== undefined) {
                address += ' ' + objectField.value.fields[4].value
              }
              if (objectField.value.fields[5].value !== undefined) {
                address += ' ' + objectField.value.fields[5].value
              }
              console.log("address value", address)
              row[opt.id] = address
            }

            if (
              field.label === 'Représentant légal' &&
              mapping.databaseObjectModelFieldName.includes('Représentant légal')
            ) {
              console.log('représentant légal', field)
              if (field.value !== undefined) {
                console.log('has a link')
                console.log('database', databaseForInsert.value)
                const linkedNode = findObjectById(databaseForInsert.value.nodes, field.value)
                console.log('linked node', linkedNode)
                if (linkedNode !== undefined) {
                  if (mapping.databaseObjectModelFieldName === 'Représentant légal/Prénom') {
                    row[opt.id] = linkedNode.fields[1].value
                  } else if (mapping.databaseObjectModelFieldName === 'Représentant légal/Nom') {
                    row[opt.id] = linkedNode.fields[2].value
                  } else {
                    row[opt.id] = linkedNode.fields[1].value + " " + linkedNode.fields[2].value
                  }
                }
              }
            }
          })
        }
        // });
      })
    }
  } catch (e) {
    console.log(e)
  }
}

watch(databaseObject, (databaseObject) => {
  addData()
  addDataWithMappingData()
})

watch(databaseChildObject, (databaseChildObject) => {
  addChildData()
})

function addSignRow() {
  props.question.answer.value.push({
    email: '',
    firstName: '',
    lastName: '',
  })
}

function removeSignRow(index: number): void {
  props.question.answer.value.splice(index, 1)
}

function showAddFormArrayModal() {
  arrayForm.value = JSON.parse(JSON.stringify(props.question.options))
  arrayForm.value.forEach((element: any) => {
    arrayValue.value[element.id] = ''
  })

  const dialog = Dialog.create({
    component: SurveyArrayFormDialog,
    componentProps: {
      question: props.question,
      isEdit: false,
      form: arrayForm.value,
      value: arrayValue.value,
    },
  })
    .onOk(() => {
      addToArray()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onEditClick(index: number) {
  arrayIndexSelected.value = index
  arrayForm.value = JSON.parse(JSON.stringify(props.question.options))
  arrayValue.value = JSON.parse(JSON.stringify(props.question.answer.value[index]))

  const dialog = Dialog.create({
    component: SurveyArrayFormDialog,
    componentProps: {
      question: props.question,
      isEdit: true,
      form: arrayForm.value,
      value: arrayValue.value,
    },
  })
    .onOk(() => {
      editRow()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function addToArray() {
  props.question.answer.value.push(JSON.parse(JSON.stringify(arrayValue.value)))
}

function editRow() {
  props.question.answer.value[arrayIndexSelected.value] = JSON.parse(JSON.stringify(arrayValue.value))
}

function onDeleteClick(index: number) {
  props.question.answer.value.splice(index, 1)
}

function showAddFormMultiArrayModal() {
  const dialog = Dialog.create({
    component: SurveyMultiArrayFormDialog,
    componentProps: {
      question: props.question,
      isEdit: false,
    },
  })
    .onOk((data) => {
      addToMultiArray(data)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showEditFormMultiArrayModal(element: any, index: number): void {
  multiArrayIndexSelected.value = index
  const form = JSON.parse(JSON.stringify(element))

  const dialog = Dialog.create({
    component: SurveyMultiArrayFormDialog,
    componentProps: {
      question: props.question,
      isEdit: true,
      form: form,
    },
  })
    .onOk((data) => {
      editMultiArrayRow(data)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function addToMultiArray(data: any): void {
  if (props.question.answer.value === undefined) {
    props.question.answer['value'] = []
  }
  const row = JSON.parse(JSON.stringify(data))
  props.question.answer.value.push(row)
}

function editMultiArrayRow(data: any): void {
  props.question.answer.value[multiArrayIndexSelected.value] = data
}

function onDeleteMultiArrayClick(index: number): void {
  props.question.answer.value.splice(index, 1)
}

function addData() {
  if (props.question.type === 'unisign') {
    console.log(databaseObject)
    databaseObject.value.fields.forEach((field) => {
      if (field.label === 'Prénom') {
        props.question.answer.value.firstName = field.value
      }
      if (field.label === 'Nom') {
        props.question.answer.value.lastName = field.value
      }
      if (field.label === 'Email') {
        props.question.answer.value.email = field.value
      }
    })
  } else if (props.question.type === 'multisign') {
    databaseObject.value.forEach((object) => {
      let sign = { email: '', firstName: '', lastName: '' }
      object?.fields.forEach((field) => {
        if (field.label === 'Prénom') {
          sign.firstName = field.value
        }
        if (field.label === 'Nom') {
          sign.lastName = field.value
        }
        if (field.label === 'Email') {
          sign.email = field.value
        }
      })
      props.question.answer.value.push(sign)
    })
  } else if (props.question.type === 'array') {
    console.log('adding array data')
    databaseObject.value.forEach((object) => {
      let row = {}
      object.fields.forEach((field) => {
        const opt = props.question.options.find((option) => {
          return field.label === option.label
        })
        if (opt) {
          row[opt.id] = field.value
        }
      })
      fillArrayRowWithMapping(object, row)
      props.question.answer.value.push(row)
    })
  } else if (props.question.type === 'multiarray') {
    databaseObject.value.forEach((object) => {
      if (object) {
        let model = props.question.options.find((option) => {
          return option.type === object.objectType
        })

        let modelNb = 0
        if (model == undefined) {
          for (modelNb = 0; modelNb < props.question.databaseObjectModelFieldsMapping.length; modelNb++) {
            if (
              props.question.databaseObjectModelFieldsMapping[modelNb].databaseObjectModelName == 'Personne physique' &&
              object.objectType == 'Personne physique'
            ) {
              model = props.question.options[modelNb]
              break
            }
            if (
              props.question.databaseObjectModelFieldsMapping[modelNb].databaseObjectModelName == 'Personne morale' &&
              object.objectType == 'SAS'
            ) {
              model = props.question.options[modelNb]
              break
            }
          }
        }

        if (model) {
          let row = { fields: [], type: '' }
          row.type = model.type

          model.fields.forEach((modelField) => {
            let fieldValue = ''
            const databaseObjectModelFieldMapping = props.question.databaseObjectModelFieldsMapping[modelNb]

            object.fields.forEach((objectField) => {
              const fieldMapping = databaseObjectModelFieldMapping.data.find((element) => {
                return (
                  element.databaseObjectModelFieldName === objectField.label &&
                  element.variableFieldName === modelField.label
                )
              })
              if (fieldMapping) {
                fieldValue = objectField.value

                if (
                  fieldMapping.variableFieldName === 'Adresse complète du siège social' ||
                  fieldMapping.variableFieldName.includes('Adresse') ||
                  fieldMapping.variableFieldName.includes('Siège social')
                ) {
                  let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
                  if (objectField.value.fields[1].value !== undefined) {
                    address += ' ' + objectField.value.fields[1].value
                  }
                  if (objectField.value.fields[2].value !== undefined) {
                    address += ' ' + objectField.value.fields[2].value
                  }
                  if (objectField.value.fields[3].value !== undefined) {
                    address += ' ' + objectField.value.fields[3].value
                  }
                  if (objectField.value.fields[4].value !== undefined) {
                    address += ' ' + objectField.value.fields[4].value
                  }
                  if (objectField.value.fields[5].value !== undefined) {
                    address += ' ' + objectField.value.fields[5].value
                  }
                  fieldValue = address
                }

                console.log('fieldMapping', fieldMapping)
                if (
                  fieldMapping.variableFieldName === 'Représentant légal'
                ) {
                  console.log('représentant légal')
                  let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
                  let databaseObjectField = objectField
                  if (databaseObjectField.value !== undefined) {
                    console.log('has a link')
                    console.log('database', databaseForInsert.value)
                    const linkedNode = findObjectById(databaseForInsert.value.nodes, databaseObjectField.value)
                    console.log('linked node', linkedNode)
                    props.question.answer.value =
                      fieldValue = linkedNode.fields[0].value + ' ' + linkedNode.fields[1].value + ' ' + linkedNode.fields[2].value
                  }
                }

              }
            })

            row.fields.push({
              id: modelField.id,
              type: modelField.type,
              label: modelField.label,
              value: fieldValue,
            })
          })

          // const databaseObjectModelFieldMapping =
          //   props.question.databaseObjectModelFieldsMapping.find(
          //     (databaseObjectModel) => {
          //       return (
          //         databaseObjectModel.databaseObjectModelName ===
          //         object.objectType
          //       );
          //     }
          //   );

          // object.fields.forEach((objectField) => {
          //   const fieldMapping = databaseObjectModelFieldMapping.data.find(
          //     (element) => {
          //       return (
          //         element.databaseObjectModelFieldName === objectField.label
          //       );
          //     }
          //   );
          //   if (fieldMapping) {
          //     const opt = model.fields.find((modelField) => {
          //       return fieldMapping.variableFieldName === modelField.label;
          //     });

          //     if (opt) {
          //       row.fields.push({
          //         id: opt.id,
          //         type: opt.type,
          //         label: opt.label,
          //         value: objectField.value,
          //       });
          //     }
          //   }
          // });

          if (props.question.answer.value === undefined) {
            props.question.answer['value'] = []
          }
          props.question.answer.value.push(row)
        }
      }
    })
  } else {
    databaseObject?.value.fields?.forEach((field) => {
      if (props.question.label === field.label) {
        props.question.answer.value = field.value
      }

      if (props.question.label === 'Intitulé du poste de travail' && field.label === 'Profession') {
        props.question.answer.value = field.value
      }

      if (props.question.label === 'Dénomination sociale' && field.label === 'Nom') {
        props.question.answer.value = field.value
      }
      if (props.question.label === "Dénomination sociale de l'employeur" && field.label === 'Nom') {
        props.question.answer.value = field.value
      }

      if (props.question.label === 'Forme sociale' && field.label === 'Forme juridique') {
        props.question.answer.value = field.value
      }
      if (props.question.label === 'Forme sociale ' && field.label === 'Forme juridique') {
        props.question.answer.value = field.value
      }
      if (props.question.label === 'Montant du capital social' && field.label === 'Capital social') {
        props.question.answer.value = field.value
      }
      if (props.question.label === 'Montant du capital' && field.label === 'Capital social') {
        props.question.answer.value = field.value
      }
      if (props.question.label === 'Adresse de siège social' && field.label === 'Adresse du siège social') {
        let objectField = field
        props.question.answer.value = field.value
      }
      if (props.question.label === 'Adresse complète du siège social' && field.label === 'Adresse du siège social') {
        let objectField = field
        let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
        if (objectField.value.fields[1].value !== undefined) {
          address += ' ' + objectField.value.fields[1].value
        }
        if (objectField.value.fields[2].value !== undefined) {
          address += ' ' + objectField.value.fields[2].value
        }
        if (objectField.value.fields[3].value !== undefined) {
          address += ' ' + objectField.value.fields[3].value
        }
        if (objectField.value.fields[4].value !== undefined) {
          address += ' ' + objectField.value.fields[4].value
        }
        if (objectField.value.fields[5].value !== undefined) {
          address += ' ' + objectField.value.fields[5].value
        }
        props.question.answer.value = address
      }
      if (props.question.label === 'Numéro de SIREN' && field.label === "Numéro d'immatriculation") {
        props.question.answer.value = field.value
      }
      if (props.question.label === 'Numéro SIREN' && field.label === "Numéro d'immatriculation") {
        props.question.answer.value = field.value
      }
      if (props.question.label === "Numéro d'immatriculation au RCS" && field.label === "Numéro d'immatriculation") {
        props.question.answer.value = field.value
      }

      if (props.question.label === "Ville d'immatriculation au RCS" && field.label === "Ville d'immatriculation") {
        props.question.answer.value = field.value
      }
      if (
        (props.question.label === 'Adresse' && field.label === 'Adresse') ||
        (props.question.label === 'Adresse du siège social' && field.label === 'Adresse du siège social') ||
        (props.question.label === 'Adresse (numéro, rue, code postal et ville)' && field.label === 'Adresse')
      ) {
        let objectField = field
        let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
        if (objectField.value.fields[1].value !== undefined) {
          address += ' ' + objectField.value.fields[1].value
        }
        if (objectField.value.fields[2].value !== undefined) {
          address += ' ' + objectField.value.fields[2].value
        }
        if (objectField.value.fields[3].value !== undefined) {
          address += ' ' + objectField.value.fields[3].value
        }
        if (objectField.value.fields[4].value !== undefined) {
          address += ' ' + objectField.value.fields[4].value
        }
        if (objectField.value.fields[5].value !== undefined) {
          address += ' ' + objectField.value.fields[5].value
        }
        props.question.answer.value = address
      }

      if (props.question.label === 'Adresse (numéro et rue)' && field.label === 'Adresse') {
        let objectField = field
        let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
        if (objectField.value.fields[1].value !== undefined) {
          address += ' ' + objectField.value.fields[1].value
        }
        if (objectField.value.fields[2].value !== undefined) {
          address += ' ' + objectField.value.fields[2].value
        }
        props.question.answer.value = address
      }

      if (
        props.question.label === 'Adresse du siège social (numéro et rue)' &&
        field.label === 'Adresse du siège social'
      ) {
        let objectField = field
        let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
        if (objectField.value.fields[1].value !== undefined) {
          address += ' ' + objectField.value.fields[1].value
        }
        if (objectField.value.fields[2].value !== undefined) {
          address += ' ' + objectField.value.fields[2].value
        }
        props.question.answer.value = address
      }

      if (props.question.label === 'Code postal' && field.label === 'Adresse') {
        let objectField = field
        let cp = objectField.value.fields[3].value !== undefined ? objectField.value.fields[3].value : ''
        props.question.answer.value = cp
      }

      if (props.question.label === 'Code postal' && field.label === 'Adresse du siège social') {
        let objectField = field
        let cp = objectField.value.fields[3].value !== undefined ? objectField.value.fields[3].value : ''
        props.question.answer.value = cp
      }

      if (props.question.label === 'Code postal du siège social' && field.label === 'Adresse du siège social') {
        let objectField = field
        let cp = objectField.value.fields[3].value !== undefined ? objectField.value.fields[3].value : ''
        props.question.answer.value = cp
      }

      if (props.question.label === 'Ville' && field.label === 'Adresse') {
        let objectField = field
        let ville = objectField.value.fields[4].value !== undefined ? objectField.value.fields[4].value : ''
        props.question.answer.value = ville
      }

      if (props.question.label === 'Ville' && field.label === 'Adresse du siège social') {
        let objectField = field
        let ville = objectField.value.fields[4].value !== undefined ? objectField.value.fields[4].value : ''
        props.question.answer.value = ville
      }

      if (props.question.label === 'Ville du siège social' && field.label === 'Adresse du siège social') {
        let objectField = field
        let ville = objectField.value.fields[4].value !== undefined ? objectField.value.fields[4].value : ''
        props.question.answer.value = ville
      }
    })
  }
}

function addChildData() {
  if (props.question.type === 'object') {
    props.question.options.forEach((option) => {
      const dataFound = databaseChildObject.value.find((data) => {
        return data.label === option.label
      })

      if (dataFound) {
        option.answer.value = dataFound.value
      }
    })
  }
  if (props.question.type === 'array') {
    databaseChildObject.value.forEach((object) => {
      let row = {}
      object.forEach((o) => {
        props.question.options.forEach((option) => {
          if (o.label === option.label) {
            row[option.id] = o.value
          }
        })
      })
      props.question.answer.value.push(row)
    })
  }
}


if (props.question.type === 'composable') {
  if (props.question.answer.value === null) {
    props.question.answer.value = [
      {
        id: "1",
        name: "Opération avec document composable 1 / Document composable 1",
        selected: true,
      },
      {
        id: "2",
        name: "Opération avec document composable 2 / Document composable 2",
        selected: true,
      },
    ]
  }
}

function hasCompositeLink(question) {
  console.log("hasCompositeLink", question, props.survey)
  if (props.survey.isCompositeProject == true) {
    return false
  }

  if (question.hasOwnProperty('compositeLink') == false) {
    return false
  }

  if (question.compositeLink !== null && question.compositeLink !== "") {
    return true
  }
  return false
}
</script>
<template>
  <div v-if="question.type === 'date'" :id="question.id.toString()">
    <BaseDatePicker v-model="question.answer.value" :label="question.label"></BaseDatePicker>
  </div>
  <div v-else-if="question.type === 'unisign'" :id="question.id.toString()">
    <BaseInput class="q-mb-md" v-model="question.answer.value.email" :type="'text'" :label="'Adresse email'">
    </BaseInput>
    <BaseInput class="q-mb-md" v-model="question.answer.value.firstName" :type="'text'" :label="'Prénom'"></BaseInput>
    <BaseInput class="q-mb-md" v-model="question.answer.value.lastName" :type="'text'" :label="'Nom'"></BaseInput>
  </div>
  <div v-else-if="question.type === 'multisign'" :id="question.id.toString()">
    <q-table :columns="multisignColumns" :rows="props.question.answer.value" row-key="email" name="multisign">
      <template #top v-if="props.question.answer.value.length">
        <q-btn icon="add" color="primary" class="q-mr-md" style="margin: auto" @click="addSignRow()">Ajouter une
          donnée</q-btn>
      </template>
      <template #no-data>
        <div class="row justify-center full-width">
          <q-btn icon="add" color="primary" @click="addSignRow()">Ajouter une donnée</q-btn>
        </div>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="action" :props="props">
            <q-btn outline rounded color="negative" padding="xs" icon="delete" class="q-pb-md"
              @click="removeSignRow(props.rowIndex)"></q-btn>
          </q-td>
          <q-td key="email" :props="props">
            <BaseInput class="q-mb-sm" v-model="props.row.email" :type="'text'" :label="'Adresse email'"></BaseInput>
          </q-td>
          <q-td key="firstname" :props="props">
            <BaseInput class="q-mb-sm" v-model="props.row.firstName" :type="'text'" :label="'Prénom'"></BaseInput>
          </q-td>
          <q-td key="lastname" :props="props">
            <BaseInput class="q-mb-sm" v-model="props.row.lastName" :type="'text'" :label="'Nom'"></BaseInput>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <div v-else-if="question.type === 'array'" :id="question.id.toString()">
    <q-table :rows="props.question.answer.value" :columns="arrayColumns" flat bordered>
      <template v-slot:body-cell-action="props">
        <q-td :props="props">
          <q-btn outline rounded class="q-mr-sm" padding="xs" color="primary" icon="edit"
            @click="onEditClick(props.rowIndex)"></q-btn>
          <q-btn outline rounded color="negative" padding="xs" icon="delete" @click="onDeleteClick(props.rowIndex)">
          </q-btn>
        </q-td>
      </template>
      <template #top v-if="props.question.answer.value.length">
        <q-btn icon="add" color="primary" class="q-mr-md" style="margin: auto" @click="showAddFormArrayModal()">Ajouter
          une donnée</q-btn>
      </template>
      <template #no-data>
        <div class="row justify-center full-width">
          <q-btn icon="add" color="primary" @click="showAddFormArrayModal()">Ajouter une donnée</q-btn>
        </div>
      </template>
    </q-table>
  </div>
  <div v-else-if="question.type === 'object'" :id="question.id.toString()">
    <div v-for="option in question.options" class="q-mb-md">
      <SurveyQuestionInput :question="option" :completeQuestion="question" :survey="survey"></SurveyQuestionInput>
    </div>
  </div>
  <div v-else-if="question.type === 'textformat'" :id="question.id.toString()">
    <editor :id="`editor-${question.id.toString()}`" api-key="2kfvzi8jvomz43fi5wztrp79sp6j2odq3i3lud6boywjbq44" :init="{
      menubar: false,
      plugins: 'lists advlist link searchreplace paste wordcount',
      toolbar:
        'undo redo | bold italic underline | align | \
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 bullist numlist | removeformat',
    }" v-model="question.answer.value" />
  </div>
  <div v-else-if="question.type === 'choice'" :id="question.id.toString()">
    <BaseSelect clearable v-model="question.answer.value" :options="question.options"
      :disable="hasCompositeLink(completeQuestion)"></BaseSelect>
  </div>
  <div v-else-if="question.type === 'multi'" :id="question.id.toString()">
    <div class="column">
      <BaseCheckbox v-for="option in question.options" :label="option" :val="option" v-model="question.answer.value">
      </BaseCheckbox>
    </div>
  </div>
  <div v-else-if="question.type === 'bool' && !isFromModal" :id="question.id.toString()">
    <BaseSelect clearable v-model="question.answer.value" :options="booleanSelectValues" emit-value map-options
      :disable="hasCompositeLink(completeQuestion)">
    </BaseSelect>

  </div>
  <div v-else-if="question.type === 'composable' && !isFromModal" :id="question.id.toString()">
    Cochez les documents à inclure
    <q-list bordered separator>
      <draggable :list="question.answer.value" :group="{ name: 'g1', pull: false, put: false }" ghost-class="ghost"
        item-key="id" class="q-gutter-sm drag-cursor">
        <template #item="{ element, index }">
          <q-item style="background-color: white;">
            <q-item-section>
              <q-item-label>
                <q-icon name="drag_handle"></q-icon>
                <q-checkbox v-model="element.selected"></q-checkbox> {{ element.name }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </draggable>
    </q-list>
  </div>
  <div v-else-if="question.type === 'multiarray' && !isFromModal" :id="question.id.toString()">
    <div class="column">
      <q-btn color="primary" icon="add" @click="showAddFormMultiArrayModal()" name="multi-array-add-btn"
        style="margin: auto; margin-bottom: 10px" :disable="hasCompositeLink(completeQuestion)">Ajouter une donnée</q-btn>
      <q-list bordered separator v-if="question.answer?.value?.length > 0">
        <draggable :list="question.answer.value" ghost-class="ghost" :item-key="question.id.toString()">
          <template #item="{ element, index }">
            <q-item class="row items-center justify-start col-12"
              :class="index < question.answer.value.length - 1 ? 'separator' : ''">
              <div class="row q-mr-md">
                <q-btn outline rounded class="q-mr-sm" padding="xs" color="primary" icon="edit"
                  @click="showEditFormMultiArrayModal(element, index)" :disable="hasCompositeLink(question)"></q-btn>
                <q-btn outline rounded color="negative" padding="xs" icon="delete" @click="onDeleteMultiArrayClick(index)"
                  :disable="hasCompositeLink(completeQuestion)"></q-btn>
              </div>
              <q-item-section class="col-auto">
                <q-item-label overline>Type</q-item-label>
                <q-item-label class="truncate-chip-labels">
                  <q-chip class="q-ml-none" :label="element.type" :title="element.type"> </q-chip>
                </q-item-label>
              </q-item-section>
              <q-item-section v-for="val in element.fields">
                <div :key="val.label" class="text-box">
                  {{ val.value }}
                </div>
              </q-item-section>
            </q-item>
          </template>
        </draggable>
      </q-list>
    </div>
  </div>
  <div v-else-if="question.type === 'number' && !isFromModal" :id="question.id.toString()">
    <BaseNumberInput v-if="isFromModal" v-model="question.value" :label="question.label"> </BaseNumberInput>

    <BaseNumberInput v-if="!isFromModal" v-model="question.answer.value" :label="question.label"> </BaseNumberInput>
  </div>
  <div v-else :id="question.id.toString()">
    <BaseInput v-if="isFromModal" v-model="question.value" :type="question.type" :label="question.label" clearable
      :disable="hasCompositeLink(completeQuestion)">
    </BaseInput>
    <BaseInput v-if="!isFromModal" v-model="question.answer.value" :type="question.type" :label="question.label" clearable
      :disable="hasCompositeLink(completeQuestion)"></BaseInput>
  </div>
</template>
<style lang="scss" scoped>
.object-label {
  font-size: 16px;
}

.modal-qestion {
  font-size: 16px;
  font-weight: 400;
}

.ghost {
  opacity: 0.5;
  background: #75a1f8;
}

.separator {
  border-bottom: 1px solid #0000001f;
}

.truncate-chip-labels {
  .q-chip {}
}

.drag-cursor {
  cursor: grab;
}

.text-box {
  flex: auto; /* Adjust the minimum width as needed */
  box-sizing: border-box;
  min-width: 50px; /* Ensure a minimum width to prevent excessive shrinking */
  height: 50px;
  padding: 1rem;
  max-width: 200px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
