<script lang="ts" setup>
import { ref, Ref, inject, watch, onMounted } from 'vue'
import { computed } from '@vue/reactivity'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import { ProjectAccountableOptionsKey, ProjectKey } from '@/models/symbols'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { UserAccountableOptions } from '@/models/projects/UserAccountableOptions'
import UserApi from '@/services/api/core/UserApi'
import NotificationsCenter from '../dialog/NotificationsCenter.vue'
import ProjectPermissionsDialog from '@/components/projects/execution/ProjectPermissionsDialog.vue'
import { toggleFavorite } from '@/utils/favoritesUtils'

const emit = defineEmits(['projectNameClicked', 'permissionsChanged'])

const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()

const isLoading: Ref<boolean> = ref(true)


const accountableOptions = inject(ProjectAccountableOptionsKey)
const project = inject(ProjectKey)
const users: Ref<Array<any>> = ref([])
const usersOptions: Ref<Array<UserAccountableOptions>> = ref([])

if (accountableOptions) {
  accountableOptions.value.map((option) => {
    usersOptions.value.push({ value: option, label: option.fullName })
  })
}
users.value = await Promise.all(
  accountableOptions?.value.map(async (user) => {
    const notificationsEnabled = await userApi.getProjectNotification(project?.value.id, user.id)

    return {
      id: user.id,
      fullName: user.fullName,
      notificationsEnabled,
    }
  }),
)

// Set authenticated user on top of the list
users.value = users.value.sort((a, b) => {
  if (a.id === authenticatedUser.id) {
    return -1
  }
  if (b.id === authenticatedUser.id) {
    return 1
  }
  return 0
})


const favorites = inject('favorites')
watch(favorites.value, () => {
  console.log('TitleBar - favorites changed')
  setFavorite()
})

const isFavorite = ref(false)

function setFavorite() {
  isFavorite.value = favorites.value.userProjects.some((favorite) => {
    return favorite.objectId === project?.value.id
  })
}

setFavorite()

async function onToggleFavorite() {
  toggleFavorite(userApi, favorites, 'user_project', project.value)
  isFavorite.value = !isFavorite.value
}

const notificationColor = computed(() => {
  return users.value.some((user) => user.notificationsEnabled) ? 'primary' : 'grey'
})
const notificationIcon = computed(() => {
  return users.value.some((user) => user.notificationsEnabled) ? 'notifications' : 'o_notifications'
})

function openNotifDialog() {
  const dialog = Dialog.create({
    component: NotificationsCenter,
    componentProps: {
      users: users?.value,
      project: project?.value,
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function projectNameClicked() {
  // emit('projectNameClicked')
  editingProjectName.value = true
}

function showPermissionsDialog() {
  console.log('showPermissionsDialog')
  // Notify.create({
  //     message: `Les paramètres des dossiers seront bientôt disponibles.`,
  //     type: "negative",
  // });
  const dialog = Dialog.create({
    component: ProjectPermissionsDialog,
    componentProps: {
      project: project,
    },
  })
    .onOk(() => {
      dialog.hide()
      console.log('permissionsChanged')
      emit('permissionsChanged')
    })
    .onCancel(() => {
      dialog.hide()
      console.log('permissionsChanged')
      emit('permissionsChanged')
    })
}

function isAllowed(permission) {
  console.log("isAllowed", permission, authenticatedUser)
  // return true

  let result = false
  console.log("isAllowed - authenticatedUser.subscriptionModules", authenticatedUser.subscriptionModules)
  authenticatedUser.subscriptionModules?.forEach((module) => {
    if (module.environmentSubscriptionModule.environmentId == authenticatedUser.environment.id && module.environmentSubscriptionModule.subscriptionModule.permissionsJson.includes(permission)) {
      result = true
    }
  })

  return result
}

const editingProjectName = ref(false)

async function onChangeProjectName() {
  console.log('onChangeProjectName')
  editingProjectName.value = false

  await userApi.updateProject(project?.value.id, {
    name: project?.value.name,
  })
  Notify.create({
    message: 'Dossier renommé',
    type: 'primary',
  })
}

onMounted(() => {
  // isLoading.value = false
  console.log("TitleBar - onMounted")
})

if (project) {
  console.log("TitleBar - project", project)
  isLoading.value = false
}

watch(project, () => {
  console.log("TitleBar - project changed")
  isLoading.value = false
})
</script>

<template>
  <div v-if="isLoading">
    <q-skeleton class="q-mb-md" style="width: 200px; height: 20px" />
  </div>
  <div v-if="!isLoading" class="row items-center no-wrap">
    <div v-if="!editingProjectName" class="project-name col-shrink" @click="projectNameClicked" style="cursor: pointer">
      {{ project?.name }}
      <span v-if="project.lopMatterId != undefined"> (Lop {{ project.lopMatterId }})</span>

      <q-tooltip>{{ project?.name }} <span v-if="project.lopMatterId != undefined"> (Lop
          {{ project.lopMatterId }})</span>
      </q-tooltip>
    </div>

    <q-input v-if="editingProjectName" size="60" v-model="project.name" dense outlined
      @keydown.enter.prevent="onChangeProjectName" /> <!-- @update:model-value="onChangeProjectNameOld" -->

    <div class="col-auto">
      <span style="margin-left: 8px">
        <q-btn v-if="!isFavorite" round flat color="grey" icon="o_star_border" :disable="isLoading"
          @click="onToggleFavorite">
          <q-tooltip>Favori</q-tooltip>
        </q-btn>
        <q-btn v-if="isFavorite" round flat style="color: #e3a93b" icon="o_star" :disable="isLoading"
          @click="onToggleFavorite">
          <q-tooltip>Favori</q-tooltip>
        </q-btn>
      </span>

      <!-- <q-btn round flat :color="notificationColor" :icon="notificationIcon" :disable="isLoading" @click="openNotifDialog">
        <q-tooltip>Centre de notifications</q-tooltip>
      </q-btn> -->

      <q-btn v-if="isAllowed('edit_project_permissions')" round flat icon="o_lock" size="12px"
        color="grey" :disable="isLoading" @click="showPermissionsDialog">
        <q-tooltip>Permissions</q-tooltip>
      </q-btn>

    </div>
  </div>
</template>

<style lang="scss" scoped>
.project-name {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
