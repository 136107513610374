<script lang="ts" setup>
import { ref, Ref, inject, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Notify, Dialog, useQuasar } from 'quasar'
import { format } from 'date-fns'
import { Task } from '@/models/projects/Task'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import { ProjectKey } from '@/models/symbols'
import UserApi from '@/services/api/core/UserApi'
import FileSaver from 'file-saver'
import DocumentCard from './DocumentCard.vue'
import DeleteDocumentDialog from './dialog/DeleteDocumentDialog.vue'
import RenameDocumentDialog from './dialog/RenameDocumentDialog.vue'
import UpdateDocumentDialog from './dialog/UpdateDocumentDialog.vue'
import CreateDocumentDialog from './dialog/CreateDocumentDialog.vue'
import AttachedFilesDialog from './dialog/AttachedFilesDialog.vue'
import PdfPreviewDialog from './dialog/PdfPreviewDialog.vue'
import PdfPreviewDialogWithIframe from '@/components/projects/execution/task_actions/document_generation/dialog/PdfPreviewDialogWithIframe.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import TaskLine from '../../TaskLine.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
  task: Task
}

const props = defineProps<Props>()

const $q = useQuasar()

const project = inject(ProjectKey)

const store = useStore()

const router = useRouter()
const route = useRoute()
const userApi: UserApi = useApi()

const generatedFiles: Ref<Array<GeneratedDocument>> = ref([])
const selectedFile = ref<GeneratedDocument>()

const isButtonLoading = ref(false)
const isLoading = ref(false)
const showAttachedFiles = ref(false)
const isMultipleFiles = ref(false)

const authenticatedUser = useAuthenticatedUser()

watch(props, (newValue, oldValue) => {
  updateFileList()
})

updateFileList()

function updateFileList() {
  console.log("props.task", props.task)

  isMultipleFiles.value = false

  if (project.value.id.length !== 36) {
    // in staging: allow multiple files for all tasks
    isMultipleFiles.value = true
  }

  if (props.task.multiDocuments) {
    isMultipleFiles.value = true
  }

  isButtonLoading.value = true
  generatedFiles.value = []
  userApi.getDocumentGenerationTaskDocuments(props.task.id).then((result) => {
    isButtonLoading.value = false
    generatedFiles.value = result.sort(
      (a, b) => new Date(b.lastGeneration).getTime() - new Date(a.lastGeneration).getTime(),
    )
  })
}



const isDocumentCreated = computed(() => {

  if (generatedFiles.value.length > 0) {
    return true
  }

  return false

  // if (import.meta.env.MODE === "production") {
  //   return isButtonLoading.value || !!generatedFiles.value.length;
  // }

})
const IsBtnDisabled = computed(() => {
  if (project?.value.isReadOnly) {
    return true
  }

  if (props.task.readOnly) {
    return true
  }

  // if (import.meta.env.MODE === "production") {
  //   return isButtonLoading.value || !!generatedFiles.value.length;
  // }

  return isButtonLoading.value
})

async function goToSurvey(documentId) {
  if (store.state.user !== null && store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/tasks/documents/survey',
      params: {
        projectId: route.params.projectId.toString(),
        documentId: documentId,
        taskId: props.task.id,
      },
      query: { page: 1 },
    })
  } else {
    router.push({
      name: 'projects/tasks/documents/survey',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
        documentId: documentId,
        taskId: props.task.id,
      },
      query: { page: 1 },
    })
  }
}

async function goToSurveySummary() {
  const documentId = generatedFiles.value[0].id

  if (store.state.user !== null && store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/tasks/documents/survey/summary',
      params: {
        projectId: route.params.projectId.toString(),
        documentId: documentId,
        taskId: props.task.id,
      },
    })
  } else {
    router.push({
      name: 'projects/tasks/documents/survey/summary',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
        documentId: documentId,
        taskId: props.task.id,
      },
    })
  }
}

async function downloadDocument(document) {
  try {
    isLoading.value = true
    const doc = await userApi.downloadTaskDocument(document.id)

    var reader = new FileReader()
    reader.readAsArrayBuffer(new Blob([doc]))
    reader.onload = function () {
      const newFile = new File([new Blob([reader.result])], 'file.docx', {
        type: 'application/octet-stream',
      })

      var arr = new Uint8Array(reader.result).subarray(0, 4)
      var header = ''
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16)
      }

      if (header == '25504446') {
        // PDF
        FileSaver(newFile, document.name + '.pdf')
      } else {
        // Assume docx
        FileSaver(newFile, document.name + '.docx')
      }
    }

    isLoading.value = false
    reader.onerror = function (error) { }
  } catch (e) {
    isLoading.value = false
    Notify.create({
      message: 'Erreur lors du téléchargement du document',
      type: 'negative',
    })
  }
}

async function updateDocument(existingFileId, newFile) {
  if (project && generatedFiles.value) {
    try {
      isLoading.value = true
      await userApi.uploadTaskDocument(existingFileId, newFile)
      isLoading.value = false
      Notify.create({
        message: 'Document mis à jour',
        type: 'primary',
      })
      updateFileList()
    } catch (e) {
      Notify.create({
        message: "Erreur lors de l'upload du document",
        type: 'negative',
      })
    }
  }
}

function showRenameDocumentModal(file: GeneratedDocument) {
  selectedFile.value = file
  const dialog = Dialog.create({
    component: RenameDocumentDialog,
    componentProps: {
      selectedFile: selectedFile.value,
      documentName: selectedFile.value.name,
    },
  }).onOk((newName) => {
    if (selectedFile) {
      const index = generatedFiles.value.findIndex((file) => file.id === selectedFile.value?.id)
      generatedFiles.value[index].name = newName
    }
    dialog.hide()
  })
}

function showUpdateDocumentDialog(file) {
  const dialog = Dialog.create({
    component: UpdateDocumentDialog,
    componentProps: {
      isLoading: isLoading.value,
    },
  })
  .onOk(async (newFile) => {
    $q.loading.show({
        message: 'Veuillez patienter pendant l\'import...',
        boxClass: 'bg-grey-2 text-grey-9',
        spinnerColor: 'primary',
        delay: 200, // ms
      })
    let existingFileId
    // If multi-document, we use the one the user selected
    if (file) {
      existingFileId = file.id
    }
    // If mono-document, we check if there is an existing one to replace
    else if (generatedFiles.value.length > 0) {
      existingFileId = generatedFiles.value.at(-1)?.id
    }
    else {
      existingFileId = await createDocument()
    }
    console.log('Updating file', existingFileId)
    await updateDocument(existingFileId, newFile)
    $q.loading.hide()
    dialog.hide()
  })
  .onCancel(() => {
    dialog.hide()
  })
}

function showDeleteDocumentModal(file: GeneratedDocument) {
  selectedFile.value = file
  const dialog = Dialog.create({
    component: DeleteDocumentDialog,
    componentProps: {
      selectedFile: selectedFile.value,
    },
  })
    .onOk(() => {
      if (selectedFile) {
        generatedFiles.value = generatedFiles.value.filter((file) => file.id !== selectedFile.value?.id)
      }
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showCreateDocumentDialog() {
  const dialog = Dialog.create({
    component: CreateDocumentDialog,
    componentProps: {
      project: project,
      task: props.task,
      generatedFiles: generatedFiles.value,
    },
  })
    .onOk((documentId) => {
      if (documentId) {
        goToSurvey(documentId)
        dialog.hide()
      } else {
        updateFileList()
      }
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function updateTaskStatus() {
  console.log('updateTaskStatus')
  if (props.task.status == 'not_started') {
    console.log('Set task as started')
    props.task.status = 'started'
    await userApi.putProjectTask(project?.value.id, props.task?.id, {
      status: 'started',
      client: route.params.clientId,
    })

    const new_event = await userApi.createProjectEvent(project?.value.id, {
      type: 1,
      user_project_id: project?.value.id,
      legacy_task_id: props.task?.id,
    })
    // emit("updateEvents", new_event);
  }
}

async function createDocument() {
  try {
    isLoading.value = true
    const document = await userApi.createDocument(props.task.documentName, props.task.id)

    console.log(project)
    if (project.value.id.length === 36) {
      await updateTaskStatus()
    } else {
      console.log('in staging - do not update task status')
    }

    isLoading.value = false
    return document.id
  } catch (e) {
    isLoading.value = false
    Notify.create({
      message: 'Erreur lors de la création du document',
      type: 'negative',
    })
  }
}

async function CreateDocumentAndStartSurvey() {
  const documentId = await createDocument()
  goToSurvey(documentId)
}

function formatFileDate(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm')
}

function openFileHelper(newWindow, file) {
  var blob = new Blob([file], { type: `application/pdf` })
  newWindow.location = URL.createObjectURL(blob)
}

async function previewFile(file) {
  $q.loading.show({
    message: 'Veuillez patienter pendant la génération de la prévisualisation PDF...',
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
    delay: 200, // ms
  })
  try {
    let doc = undefined
    // if (file.name.endsWith(".docx")) {
    doc = await userApi.convertGeneratedFileToPdf(file.id)
    // } else {
    // doc = await userApi.downloadTaskDocument(file.id);
    // }

    let newWindow = window.open('')
    if (newWindow.document.readyState === 'complete') {
      openFileHelper(newWindow, doc)
    } else {
      newWindow.onload = () => {
        openFileHelper(newWindow, doc)
      }
    }
    $q.loading.hide()
  } catch (e) {
    $q.loading.hide()
    console.error(e)
    Notify.create({
      message: 'Erreur lors de la prévisualisation du document',
      type: 'negative',
    })
  }
}

// function showPdfPreview(file: GeneratedDocument) {
//   selectedFile.value = file;

//   const dialog = Dialog.create({
//     component: PdfPreviewDialog,
//     componentProps: {
//       file: file.id,
//       documentName: file.name,
//       taskId: props.task.id,
//     },
//   })
//     .onOk(async (data) => {
//       dialog.hide();
//     })
//     .onCancel(() => {
//       dialog.hide();
//     });
// }

async function showPdfPreview(file: GeneratedDocument) {
  const BASE_URL = import.meta.env.VITE_API_BASE_URL
  let filenameConverted = encodeURIComponent(file.name)
  let fileUrl = BASE_URL + `api/v2/tasks/convert_generated_file_to_pdf/${file.id}/${filenameConverted}?type=preview`
  const dialog = Dialog.create({
    component: PdfPreviewDialogWithIframe,
    componentProps: {
      // file: file.id,
      documentName: file.name,
      // taskId: props.task.id,
      fileUrl: fileUrl,
    },
  })
    .onOk(async (data) => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function showPdfPreviewIfFileIsGenerated(file: GeneratedDocument) {
  if (file.isDownloadable) {
    await showPdfPreview(file)
    return
  }

  console.log('File is not generated yet')
  Notify.create({
    message: "Le document n'a pas encore été généré.",
    type: 'negative',
  })
}

async function updateDocumentTemplate(file: GeneratedDocument) {
  $q.loading.show({
    message: 'Veuillez patienter pendant la mise à jour du modèle de document...',
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
    delay: 200, // ms
  })
  try {
    await new Promise((r) => setTimeout(r, 2000))

    $q.loading.hide()
    Notify.create({
      message: 'Le modèle de document a bien été mis à jour.',
      type: 'primary',
    })
  } catch (e) {
    $q.loading.hide()
    console.error(e)
    Notify.create({
      message: 'Erreur lors de la mise à jour du modèle de document',
      type: 'negative',
    })
  }
}

function showAttachedFilesDialog(file: GeneratedDocument) {
  const dialog = Dialog.create({
    component: AttachedFilesDialog,
    componentProps: {
      file: file,
    },
  })
    .onOk(async (data) => {
      dialog.hide()
      showAttachedFiles.value = true
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function strToUTF8Arr(sDOMStr) {
  let aBytes
  let nChr
  const nStrLen = sDOMStr.length
  let nArrLen = 0

  /* correspondance… */
  for (let nMapIdx = 0; nMapIdx < nStrLen; nMapIdx++) {
    nChr = sDOMStr.codePointAt(nMapIdx)
    if (nChr >= 0x10000) {
      nMapIdx++
    }
    nArrLen += nChr < 0x80 ? 1 : nChr < 0x800 ? 2 : nChr < 0x10000 ? 3 : nChr < 0x200000 ? 4 : nChr < 0x4000000 ? 5 : 6
  }
  aBytes = new Uint8Array(nArrLen)

  /* transposition… */
  let nIdx = 0
  let nChrIdx = 0
  while (nIdx < nArrLen) {
    nChr = sDOMStr.codePointAt(nChrIdx)
    if (nChr < 128) {
      /* un octet */
      aBytes[nIdx++] = nChr
    } else if (nChr < 0x800) {
      /* deux octets */
      aBytes[nIdx++] = 192 + (nChr >>> 6)
      aBytes[nIdx++] = 128 + (nChr & 63)
    } else if (nChr < 0x10000) {
      /* trois octets */
      aBytes[nIdx++] = 224 + (nChr >>> 12)
      aBytes[nIdx++] = 128 + ((nChr >>> 6) & 63)
      aBytes[nIdx++] = 128 + (nChr & 63)
    } else if (nChr < 0x200000) {
      /* quatre octets */
      aBytes[nIdx++] = 240 + (nChr >>> 18)
      aBytes[nIdx++] = 128 + ((nChr >>> 12) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 6) & 63)
      aBytes[nIdx++] = 128 + (nChr & 63)
      nChrIdx++
    } else if (nChr < 0x4000000) {
      /* cinq octets */
      aBytes[nIdx++] = 248 + (nChr >>> 24)
      aBytes[nIdx++] = 128 + ((nChr >>> 18) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 12) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 6) & 63)
      aBytes[nIdx++] = 128 + (nChr & 63)
      nChrIdx++
    } /* if (nChr <= 0x7fffffff) */ else {
      /* six octets */
      aBytes[nIdx++] = 252 + (nChr >>> 30)
      aBytes[nIdx++] = 128 + ((nChr >>> 24) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 18) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 12) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 6) & 63)
      aBytes[nIdx++] = 128 + (nChr & 63)
      nChrIdx++
    }
    nChrIdx++
  }
  return aBytes
}



/* Chaîne en base64 encodée vers un tableau */
function uint6ToB64(nUint6) {
  return nUint6 < 26
    ? nUint6 + 65
    : nUint6 < 52
      ? nUint6 + 71
      : nUint6 < 62
        ? nUint6 - 4
        : nUint6 === 62
          ? 43
          : nUint6 === 63
            ? 47
            : 65
}

function base64EncArr(aBytes) {
  let nMod3 = 2
  let sB64Enc = ''
  const nLen = aBytes.length
  let nUint24 = 0
  for (let nIdx = 0; nIdx < nLen; nIdx++) {
    nMod3 = nIdx % 3
    // Décommenter le test qui suit afin de découper la
    // chaîne base64 en plusieurs lignes de 80 caractères
    //if (nIdx > 0 && ((nIdx * 4) / 3) % 76 === 0) {
    //  sB64Enc += "\r\n";
    //}
    nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24)
    if (nMod3 === 2 || aBytes.length - nIdx === 1) {
      sB64Enc += String.fromCodePoint(
        uint6ToB64((nUint24 >>> 18) & 63),
        uint6ToB64((nUint24 >>> 12) & 63),
        uint6ToB64((nUint24 >>> 6) & 63),
        uint6ToB64(nUint24 & 63),
      )
      nUint24 = 0
    }
  }
  return sB64Enc.substring(0, sB64Enc.length - 2 + nMod3) + (nMod3 === 2 ? '' : nMod3 === 1 ? '=' : '==')
}


function editFile(element, editor) {
  let command = ''
  if (editor === 'Word') {
    command = 'launch_word'
  }
  if (editor === 'Excel') {
    command = 'launch_excel'
  }
  if (editor === 'PowerPoint') {
    command = 'launch_powerpoint'
  }

  const baseUrl =
    'https://api.staging.comeonlaw.com/api/v1/legacy/documents/' +
    element.id

  // const baseUrl =
  //   'https://192.168.0.19/api/api/v1/legacy/documents/' +
  //   element.id

  const downloadUrl = '/download/fake_filename?responseType=blob'
  const uploadUrl = '/upload'
  const lockUrl = '/lock'
  const unlockUrl = '/unlock'
  const fileInfoUrl = '/file_info'

  const deepLinkData = {
    command: 'launch_word',
    user: {
      id: authenticatedUser.id,
      email: authenticatedUser.email,
      firstName: authenticatedUser.firstName,
      lastName: authenticatedUser.lastName,
      fullName: authenticatedUser.fullname,
      accessToken: authenticatedUser.accessToken,
    },
    file: {
      name: element.name + ".docx",
      type: 'generated_document',
      id: element.id.toString(),
      baseUrl: baseUrl,
      download: {
        method: 'GET',
        url: downloadUrl,
      },
      upload: {
        method: 'POST',
        url: uploadUrl,
      },
      lock: {
        method: 'POST',
        url: lockUrl,
      },
      unlock: {
        method: 'POST',
        url: unlockUrl,
      },
      info: {
        method: 'GET',
        url: fileInfoUrl,
      },
    },
  }

  console.log(deepLinkData)

  // const base64Encoded = utf8_to_b64(JSON.stringify(deepLinkData));
  const deepLinkDataUTF8 = strToUTF8Arr(JSON.stringify(deepLinkData))
  const base64Encoded = base64EncArr(deepLinkDataUTF8)

  const deepLinkUrl = 'office-manager://files/open?data=' + encodeURIComponent(base64Encoded)

  console.log(deepLinkUrl)
  console.log(deepLinkUrl.length)

  window.location = deepLinkUrl
}
</script>

<template>
  <div class="row field items-top">
    <div class="field-title task-field-title col-auto">
      <p>Actions</p>
    </div>
    <div class="q-pl-md col">
      <q-btn v-if="isMultipleFiles" color="primary" size="13px" padding="xs md" label="Ajouter un document"
        @click="showCreateDocumentDialog()" :loading="isButtonLoading" :disable="IsBtnDisabled" />

      <q-btn v-if="!isMultipleFiles && !isDocumentCreated" color="primary" size="13px" padding="xs md" label="Démarrer le questionnaire"
        @click="CreateDocumentAndStartSurvey()" :loading="isButtonLoading" :disable="IsBtnDisabled" />

      <q-btn v-if="!isMultipleFiles && isDocumentCreated" color="primary" size="13px" padding="xs md" label="Ouvrir le questionnaire"
        @click="goToSurveySummary()" :loading="isButtonLoading" :disable="IsBtnDisabled" />

      <div v-if="!isMultipleFiles" style="margin-top: 16px; font-size: 13px">
        <a href="#" class="button-link" @click="showUpdateDocumentDialog(null)">
          Importer mon propre document</a>
      </div>

      <div v-if="project?.id.length!==36" style="margin-top: 16px; font-size: 13px">
        <a href="#" class="button-link" @click="autoFill()">
          Remplir le questionnaire automatiquement</a>
      </div>
    </div>

    <div class="full-width" v-if="generatedFiles.length > 0">
      <template v-for="file in generatedFiles">
        <DocumentCard class="q-mt-md" v-if="file.isDownloadable" :key="file.id">
          <template #name>
            <div @click="showPdfPreviewIfFileIsGenerated(file)" style="cursor: pointer">
              {{ file.name }}
            </div>
          </template>
          <template #caption>
            <div @click="showPdfPreviewIfFileIsGenerated(file)" style="cursor: pointer">
              <p v-if="file.uploaded">Mis à jour : {{ formatFileDate(file.lastGeneration) }}</p>
              <p v-else>Généré : {{ formatFileDate(file.lastGeneration) }}</p>

            </div>
          </template>
          <template #body v-if="showAttachedFiles">
            <div @click="showPdfPreviewIfFileIsGenerated(file)" style="cursor: pointer; display: block">
              <div>annexe1.pdf</div>
              <div>annexe2.pdf</div>
            </div>
          </template>
          <template #actions>
            <q-btn-dropdown flat rounded dropdown-icon="more_vert" name="document-options-btn">
              <q-list dense>
                <!-- <q-item-label header>QUESTIONNAIRE</q-item-label>
                <q-item clickable v-close-popup @click="goToSurveySummary(file.id)" :disable="project.isReadOnly">
                  <q-item-section>
                    <q-item-label>Continuer</q-item-label>
                  </q-item-section>
                </q-item>
                <q-separator spaced />
                <q-item-label header>DOCUMENT</q-item-label> -->
                <q-item clickable :disable="!file.isDownloadable" v-close-popup @click="editFile(file, 'Word')">
                  <q-item-section>
                    <q-item-label>Editer dans Word</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable :disable="!file.isDownloadable" v-close-popup @click="downloadDocument(file)">
                  <q-item-section>
                    <q-item-label>Télécharger</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup :disable="!file.isDownloadable" @click="showPdfPreview(file)">
                  <q-item-section>
                    <q-item-label> Prévisualiser </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup :disable="project.isReadOnly" @click="showRenameDocumentModal(file)">
                  <q-item-section>
                    <q-item-label>Renommer</q-item-label>
                  </q-item-section>
                </q-item>
                <!-- On garde cette option pour les tâches multi-documents, pour pouvoir choisir le document à remplacer-->
                <q-item clickable v-if="isMultipleFiles" v-close-popup :disable="project.isReadOnly" @click="showUpdateDocumentDialog(file)">
                  <q-item-section>
                    <q-item-label> Importer mon propre document </q-item-label>
                  </q-item-section>
                </q-item>
                <!-- <q-item clickable v-close-popup :disable="project.isReadOnly">
                                  <q-item-section>
                                    <q-item-label @click="showAttachedFilesDialog(file)">
                                      Gérer les pièces jointes
                                    </q-item-label>
                                  </q-item-section>
                                </q-item>               -->
                <!-- <q-item clickable v-close-popup :disable="project.isReadOnly" @click="updateDocumentTemplate()">
                  <q-item-section>
                    <q-item-label>Mettre à jour le modèle</q-item-label>
                  </q-item-section>
                </q-item> -->
                <q-item clickable v-close-popup class="text-red" :disable="project.isReadOnly"
                  @click="showDeleteDocumentModal(file)">
                  <q-item-section>
                    <q-item-label text-color="negative">Supprimer</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </template>
        </DocumentCard>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.task-field-title {
  width: 80px;
}
</style>
