import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { Group } from '@/models/Group'
import BackOfficeApi from '../BackOfficeApi'
import { Project } from '@/models/projects/Project'
import { User } from '@/models/core/User'
import { Mail } from '@/models/core/Mail'
import axios from 'axios'
import { Survey } from '@/models/survey/Survey'
import { Database } from '@/models/database/Database'
import UuidEncoder from 'uuid-encoder'
import { validate as uuidValidate } from 'uuid'

type OrNull<Type> = Type | null
const encoder = new UuidEncoder('base62')

class UserApi {
  public api: BackOfficeApi

  constructor(api: BackOfficeApi) {
    this.api = api
  }

  getBaseUrl(): string {
    return this.api.getBaseUrl()
  }

  getCurrentUserBaseUrl(): string {
    return this.api.getCurrentUserBaseUrl()
  }

  getCurrentEnvironmentBaseUrl(): string {
    return this.api.getCurrentEnvironmentBaseUrl()
  }

  getCurrentClientBaseUrl(): string {
    return this.api.getCurrentClientBaseUrl()
  }

  getCurrentPortal(): string {
    return this.api.getCurrentPortal()
  }

  isInClientContext(clientId: string | null) {
    const portal = this.getCurrentPortal()
    console.log('portal', portal)
    if (portal == 'CLIENT' || clientId != undefined) {
      return true
    }
    return false
  }

  ////////////////////////////
  // User
  ////////////////////////////

  async getUser(userId: string): Promise<void> {
    return this.api.get(this.getBaseUrl() + '/users/' + userId + '/')
  }

  async getUserByPkid(userId: string): Promise<void> {
    return this.api.get(this.getBaseUrl() + '/users/get_by_pkid?pkid=' + userId)
  }

  async getUserByEmail(email: string): Promise<void> {
    return this.api.post(this.getBaseUrl() + '/users/get_user_by_email/', {
      email: email,
    })
  }

  async impersonate(userId: string): Promise<void> {
    return this.api.get(this.getBaseUrl() + '/users/' + userId + '/start_impersonate')
  }

  async stopImpersonate(userId: string): Promise<void> {
    return this.api.get(this.getBaseUrl() + '/users/' + userId + '/stop_impersonate')
  }

  ////////////////////////////
  // Platform
  ////////////////////////////

  async getAllEnvironments(): Promise<void> {
    return this.api.get('api/v1/environments')
  }

  async getEnvironment(id): Promise<void> {
    return this.api.get('api/v1/environments/' + id)
  }

  async createEnvironment(name): Promise<void> {
    return this.api.post('api/v1/environments/', {
      name: name,
    })
  }

  async renameEnvironment(environmentId, newName): Promise<void> {
    return this.api.put('api/v1/environments/' + environmentId + '/', {
      name: newName,
    })
  }

  ////////////////////////////
  // Payment methods
  ////////////////////////////

  async getPaymentMethods(): Promise<void> {
    return this.api.get(this.getCurrentUserBaseUrl() + '/payment_methods/')
  }

  async createPaymentMethod(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentUserBaseUrl() + '/payment_methods/', parameters)
  }

  async deletePaymentMethod(paymentMethodId: string): Promise<void> {
    return this.api.delete(this.getCurrentUserBaseUrl() + '/payment_methods/' + paymentMethodId)
  }

  async updatePaymentMethod(paymentMethodId: string, parameters: any): Promise<void> {
    return this.api.put(this.getCurrentUserBaseUrl() + '/payment_methods/' + paymentMethodId + '/', parameters)
  }

  ////////////////////////////
  // Subscriptions
  ////////////////////////////

  async getSubscriptionModules(): Promise<void> {
    return this.api.get(this.getBaseUrl() + '/subscription_modules/')
  }

  async getEnvironmentSubscriptionModules(environmentId): Promise<void> {
    return this.api.get(this.getBaseUrl() + '/environments/' + environmentId + '/subscription_modules/')
  }

  async getSubscriptionModule(subscriptionModuleId): Promise<void> {
    return this.api.get(this.getBaseUrl() + '/subscription_modules/' + subscriptionModuleId + '/')
  }

  async updateSubscriptionModule(subscriptionModule): Promise<void> {
    return this.api.put(this.getBaseUrl() + '/subscription_modules/' + subscriptionModule.id + '/', subscriptionModule)
  }

  async updateUserSubscriptionModules(userId, modules): Promise<void> {
    return this.api.post(this.getBaseUrl() + '/users/' + userId + '/update_subscriptions/', modules)
  }

  // async getSubscriptions(): Promise<void> {
  //   return this.api.get(this.getCurrentEnvironmentBaseUrl() + '/subscriptions/')
  // }

  // async getUserSubscriptions(): Promise<void> {
  //   return this.api.get(this.getCurrentUserBaseUrl() + '/subscriptions/')
  // }

  // async purchaseSubscription(subscriptionId: string, parameters: any): Promise<void> {
  //   return this.api.post(
  //     this.getCurrentEnvironmentBaseUrl() + '/subscriptions/' + subscriptionId + '/purchase/',
  //     parameters,
  //   )
  // }

  // async deleteSubscription(subscriptionId: string): Promise<void> {
  //   return this.api.delete(this.getCurrentUserBaseUrl() + '/subscriptions/' + subscriptionId)
  // }

  // async updateSubscription(subscriptionId: string, parameters: any): Promise<void> {
  //   return this.api.put(this.getCurrentUserBaseUrl() + '/subscriptions/' + subscriptionId + '/', parameters)
  // }

  ////////////////////////////
  // Credit packs
  ////////////////////////////

  async getCreditPacks(): Promise<void> {
    return this.api.get(this.getCurrentEnvironmentBaseUrl() + '/credit_packs/')
  }

  async getUserCreditPacks(): Promise<void> {
    return this.api.get(this.getCurrentUserBaseUrl() + '/credit_packs/')
  }

  async purchaseCreditPack(creditPackId: string, parameters: any): Promise<void> {
    return this.api.post(
      this.getCurrentEnvironmentBaseUrl() + '/credit_packs/' + creditPackId + '/purchase/',
      parameters,
    )
  }

  async updateObjectPermissions(
    objectId: any,
    objectType: any,
    userIds: any,
    addPermissions: any,
    removePermissions: any,
  ): Promise<void> {
    const parameters = {
      objectId: objectId,
      objectType: objectType,
      users: userIds,
      addPermissions: addPermissions,
      removePermissions: removePermissions,
    }

    return await this.api.post('api/v1/users/update_object_permissions/', parameters)
  }

  async getObjectPermissions(objectId: any, objectType: any, userId: any): Promise<void> {
    const parameters = {
      objectId: objectId,
      objectType: objectType,
    }

    return await this.api.post('api/v1/users/' + userId + '/get_object_permissions/', parameters)
  }

  async getObjectsPermissions(objectIds: any, objectType: any, userId: any): Promise<void> {
    const parameters = {
      objectIds: objectIds,
      objectType: objectType,
    }

    return await this.api.post('api/v1/users/' + userId + '/get_objects_permissions/', parameters)
  }

  async getUsersWithObjectPermissions(objectId: any, objectType: any): Promise<Array<User>> {
    const parameters = {
      objectId: objectId,
      objectType: objectType,
    }

    return await this.api.post('api/v1/users/get_users_with_object_permissions/', parameters)
  }

  async getUsersWithObjectPermissionsStrict(objectId: any, objectType: any): Promise<Array<User>> {
    const parameters = {
      objectId: objectId,
      objectType: objectType,
    }

    return await this.api.post('api/v1/users/get_users_with_object_permissions_strict/', parameters)
  }

  ////////////////////////////
  // Groups
  ////////////////////////////

  async getPortalGroups(clientId: OrNull<string>): Promise<Group[]> {
    if (this.isInClientContext(clientId)) {
      return await this.getClientGroups()
    } else {
      return await this.getEnvironmentGroups()
    }
  }

  async getPortalGroup(groupId: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.getClientGroup(groupId)
    } else {
      return this.getEnvironmentGroup(groupId)
    }
  }

  async createPortalGroup(parameters: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.createClientGroup(parameters)
    } else {
      return this.createEnvironmentGroup(parameters)
    }
  }

  async updatePortalGroup(groupId: any, parameters: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.updateClientGroup(groupId, parameters)
    } else {
      return this.updateEnvironmentGroup(groupId, parameters)
    }
  }

  async updateObjectGroupsPermissions(
    objectId: any,
    objectType: any,
    groupIds: any,
    addPermissions: any,
    removePermissions: any,
  ): Promise<void> {
    const parameters = {
      objectId: objectId,
      objectType: objectType,
      groups: groupIds,
      addPermissions: addPermissions,
      removePermissions: removePermissions,
    }

    return await this.api.post('api/v1/users/update_object_groups_permissions/', parameters)
  }

  async updateObjectGroupPermissions(
    objectId: any,
    objectType: any,
    groupId: any,
    addPermissions: any,
    removePermissions: any,
  ): Promise<void> {
    const parameters = {
      objectId: objectId,
      objectType: objectType,
      groups: [groupId],
      addPermissions: addPermissions,
      removePermissions: removePermissions,
    }

    return await this.api.post('api/v1/users/update_object_groups_permissions/', parameters)
  }

  async getObjectGroupPermissions(objectId: any, objectType: any, groupId: any): Promise<void> {
    const parameters = {
      objectId: objectId,
      objectType: objectType,
    }

    return await this.api.post(
      this.getCurrentEnvironmentBaseUrl() + '/groups/' + groupId + '/get_object_group_permissions/',
      parameters,
    )
  }

  async getObjectsGroupPermissions(objectIds: any, objectType: any, groupId: any): Promise<void> {
    const parameters = {
      objectIds: objectIds,
      objectType: objectType,
    }

    return await this.api.post(
      this.getCurrentEnvironmentBaseUrl() + '/groups/' + groupId + '/get_objects_group_permissions/',
      parameters,
    )
  }

  async deletePortalGroup(groupId: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.deleteClientGroup(groupId)
    } else {
      return this.deleteEnvironmentGroup(groupId)
    }
  }

  async addUsersToPortalGroup(groupId: any, userIds: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.addUsersToClientGroup(groupId, userIds)
    } else {
      return this.addUsersToEnvironmentGroup(groupId, userIds)
    }
  }

  async removeUsersToPortalGroup(groupId: any, userIds: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.removeUsersToClientGroup(groupId, userIds)
    } else {
      return this.removeUsersToEnvironmentGroup(groupId, userIds)
    }
  }

  async getEnvironmentGroups(): Promise<Group[]> {
    const result = await this.api.get(this.getCurrentEnvironmentBaseUrl() + '/groups/')
    return result.results
  }

  async getEnvironmentGroup(groupId: string): Promise<void> {
    return this.api.get(this.getCurrentEnvironmentBaseUrl() + '/groups/' + groupId + '/')
  }

  async createEnvironmentGroup(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentEnvironmentBaseUrl() + '/groups/', parameters)
  }

  async updateEnvironmentGroup(groupId: string, parameters: any): Promise<void> {
    return this.api.put(this.getCurrentEnvironmentBaseUrl() + '/groups/' + groupId + '/', parameters)
  }

  async deleteEnvironmentGroup(groupId: string): Promise<void> {
    return this.api.delete(this.getCurrentEnvironmentBaseUrl() + '/groups/' + groupId + '/')
  }

  async addUsersToEnvironmentGroup(groupId: string, userIds: any): Promise<void> {
    return this.api.post(this.getCurrentEnvironmentBaseUrl() + '/groups/' + groupId + '/add_users/', userIds)
  }

  async removeUsersToEnvironmentGroup(groupId: string, userIds: any): Promise<void> {
    return this.api.post(this.getCurrentEnvironmentBaseUrl() + '/groups/' + groupId + '/remove_users/', userIds)
  }

  async getClientGroups(): Promise<Group[]> {
    const result = await this.api.get(this.getCurrentClientBaseUrl() + '/groups/')
    return result.results
  }

  async getClientGroup(groupId: string): Promise<void> {
    return this.api.get(this.getCurrentClientBaseUrl() + '/groups/' + groupId + '/')
  }

  async createClientGroup(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentClientBaseUrl() + '/groups/', parameters)
  }

  async updateClientGroup(groupId: string, parameters: any): Promise<void> {
    return this.api.put(this.getCurrentClientBaseUrl() + '/groups/' + groupId + '/', parameters)
  }

  async deleteClientGroup(groupId: string): Promise<void> {
    return this.api.delete(this.getCurrentClientBaseUrl() + '/groups/' + groupId + '/')
  }

  async addUsersToClientGroup(groupId: string, userIds: any): Promise<void> {
    return this.api.post(this.getCurrentClientBaseUrl() + '/groups/' + groupId + '/add_users/', userIds)
  }

  async removeUsersToClientGroup(groupId: string, userIds: any): Promise<void> {
    return this.api.post(this.getCurrentClientBaseUrl() + '/groups/' + groupId + '/remove_users/', userIds)
  }

  async getGroupsWithObjectPermissions(objectId: any, objectType: any): Promise<void> {
    var parameters = {
      objectId: objectId,
      objectType: objectType,
    }

    return this.api.post('api/v1/users/get_groups_with_object_permissions/', parameters)
  }

  ////////////////////////////
  // Users
  ////////////////////////////

  async getPortalUsers(clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return await this.getClientUsers()
    } else {
      return await this.getEnvironmentUsers()
    }
  }

  async getClientUsersForce(clientId: OrNull<string>): Promise<void> {
    return await this.api.get(this.getCurrentEnvironmentBaseUrl() + '/clients/' + clientId + '/users/')
  }

  async getClientGroupsForce(clientId: OrNull<string>): Promise<void> {
    return await this.api.get(this.getCurrentEnvironmentBaseUrl() + '/clients/' + clientId + '/groups/')
  }

  async createPortalUser(parameters: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.createClientUser(parameters)
    } else {
      return this.createEnvironmentUser(parameters)
    }
  }

  async invitePortalUser(parameters: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.inviteClientUser(parameters)
    } else {
      return this.inviteEnvironmentUser(parameters)
    }
  }

  async updatePortalUser(userId: any, parameters: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.updateClientUser(userId, parameters)
    } else {
      return this.updateEnvironmentUser(userId, parameters)
    }
  }

  async deletePortalUser(userId: any, clientId: OrNull<string>): Promise<void> {
    if (this.isInClientContext(clientId)) {
      return this.deleteClientUser(userId)
    } else {
      return this.deleteEnvironmentUser(userId)
    }
  }

  async getEnvironmentUsers(): Promise<void> {
    return await this.api.get(this.getCurrentEnvironmentBaseUrl() + '/users/')
  }

  async getEnvironmentUsersBase(): Promise<void> {
    return await this.api.get(this.getCurrentEnvironmentBaseUrl() + '/users/base')
  }

  async createEnvironmentUser(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentEnvironmentBaseUrl() + '/users/', parameters)
  }

  async addEnvironmentToUser(userId: string, parameters: any): Promise<void> {
    return this.api.post(this.getBaseUrl() + '/users/' + userId + '/add_environment/', parameters)
  }

  async removeEnvironmentFromUser(userId: string, parameters: any): Promise<void> {
    return this.api.post(this.getBaseUrl() + '/users/' + userId + '/remove_environment/', parameters)
  }

  async sendEnvironmentInvitation(userId: string, parameters: any): Promise<void> {
    return this.api.post(this.getBaseUrl() + '/users/' + userId + '/send_environment_invitation/', parameters)
  }

  async promoteClientUserToEnvironmentUser(userId: string, clientIdToAdd: any): Promise<void> {
    return this.api.post(this.getBaseUrl() + '/users/' + userId + '/promote_client_user_to_environment_user/', {
      clientIdToAdd: clientIdToAdd,
    })
  }

  async updateEnvironmentUser(userId: string, parameters: any): Promise<void> {
    return this.api.put(this.getCurrentEnvironmentBaseUrl() + '/users/' + userId + '/', parameters)
  }

  async deleteEnvironmentUser(userId: string): Promise<void> {
    return this.api.delete(this.getCurrentEnvironmentBaseUrl() + '/users/' + userId + '/')
  }

  async getClientUsers(): Promise<void> {
    return await this.api.get(this.getCurrentClientBaseUrl() + '/users/')
  }

  async createClientUser(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentClientBaseUrl() + '/users/', parameters)
  }

  async inviteClientUser(parameters: any): Promise<void> {
    return this.api.post('api/v1/invite_user', parameters)
  }

  async inviteEnvironmentUser(parameters: any): Promise<void> {
    return this.api.post('api/v1/invite_user', parameters)
  }

  async updateClientUser(userId: string, parameters: any): Promise<void> {
    return this.api.put(this.getCurrentClientBaseUrl() + '/users/' + userId + '/', parameters)
  }

  async deleteClientUser(userId: string): Promise<void> {
    return this.api.delete(this.getCurrentClientBaseUrl() + '/users/' + userId + '/')
  }

  async getUserAssignedTasks(userId): Promise<void> {
    return this.api.post('api/v1/legacy/tasks/user_assigned_tasks', {
      userId: userId,
    })
  }

  ////////////////////////////
  // Clients
  ////////////////////////////

  async getClients(): Promise<void> {
    return await this.api.get(this.getCurrentEnvironmentBaseUrl() + '/clients/')
  }

  async getClient(clientId: string): Promise<void> {
    return this.api.get(this.getCurrentEnvironmentBaseUrl() + '/clients/' + clientId + '/')
  }

  async getClientWithoutEnvironment(clientId: string): Promise<void> {
    return this.api.get(this.getBaseUrl() + '/clients/' + clientId + '/')
  }

  async createClient(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentEnvironmentBaseUrl() + '/clients/', parameters)
  }

  async updateClient(clientId: string, parameters: any): Promise<void> {
    return this.api.put(this.getCurrentEnvironmentBaseUrl() + '/clients/' + clientId + '/', parameters)
  }

  async deleteClient(clientId: string): Promise<void> {
    return this.api.delete(this.getCurrentEnvironmentBaseUrl() + '/clients/' + clientId + '/')
  }

  ////////////////////////////
  // Projects
  ////////////////////////////
  async getProjects(page: number, filter: string, rowsPerPage: number): Promise<void> {
    let url = `${this.getCurrentClientBaseUrl()}/projects?page=${page}&page_size=${rowsPerPage}`
    if (filter) {
      url += `&filter=${filter}`
    }
    return await this.api.get(url)
  }

  async getClientProjects(page: number, filter: string, rowsPerPage: number, clientId: string) {
    let url = `${this.getCurrentEnvironmentBaseUrl()}/clients/${clientId}/projects?page=${page}&page_size=${rowsPerPage}`
    if (filter) {
      url += `&filter=${filter}`
    }
    return await this.api.get(url)
  }

  async getAllUserProjects(page: number, filter: string, rowsPerPage: number): Promise<void> {
    let url = `${this.getCurrentUserBaseUrl()}/projects?page=${page}&page_size=${rowsPerPage}`
    if (filter) {
      url += `&filter=${filter}`
    }
    return await this.api.get(url)
  }

  async getRecentlyViewedProjects(page: number, filter: string, rowsPerPage: number): Promise<void> {
    let url = `${this.getCurrentUserBaseUrl()}/projects/recently_viewed?page=${page}&page_size=${rowsPerPage}`
    if (filter) {
      url += `&filter=${filter}`
    }
    return await this.api.get(url)
  }

  async getProject(projectId: string): Promise<Project> {
    // const id = uuidValidate(projectId) ? projectId : encoder.decode(projectId);
    const id = projectId
    return await this.api.get(this.getCurrentClientBaseUrl() + '/projects/' + id + '/get_name')
  }

  async getProjectEvents(projectId: string, taskId: string, documentId: string): Promise<void> {
    const id = projectId
    return await this.api.get(this.getCurrentClientBaseUrl() + '/projects/' + id + '/events/')
  }

  async createProjectEvent(projectId: string, data): Promise<void> {
    const id = projectId
    return await this.api.post(this.getCurrentClientBaseUrl() + '/projects/' + id + '/events/', data)
  }

  async getProjectTemplates(): Promise<void> {
    return await this.api.get(this.getCurrentClientBaseUrl() + '/project_templates')
  }

  async getProducts(): Promise<void> {
    return await this.api.get(this.getCurrentEnvironmentBaseUrl() + '/products/')
  }

  async getProductByLegacyTemplateId(legacyTemplateId: string): Promise<void> {
    return this.api.get(this.getCurrentEnvironmentBaseUrl() + '/products/?legacyTemplateId=' + legacyTemplateId)
  }

  async getProjectTemplateByLegacyId(legacyId: string): Promise<void> {
    return await this.api.get(this.getCurrentClientBaseUrl() + '/project_templates/?legacyId=' + legacyId)
  }

  async createProject(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentClientBaseUrl() + '/projects/', parameters)
  }

  async createSubProjects(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentClientBaseUrl() + '/projects/create_sub_projects/', parameters)
  }

  async deleteSubProject(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentClientBaseUrl() + '/projects/delete_sub_project/', parameters)
  }

  async renameSubProject(legacyProjectId: string, newName: string) {
    try {
      console.log('renameSubProject', legacyProjectId, newName)
      await this.api.post(this.getCurrentClientBaseUrl() + '/projects/rename_sub_project/', {
        legacyProjectId: legacyProjectId,
        newName: newName,
      })
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async createPreloadedProject(parameters: any): Promise<void> {
    return this.api.post(this.getCurrentClientBaseUrl() + '/projects/create_preloaded_project/', parameters)
  }

  async updateProject(projectId: string, parameters: any): Promise<void> {
    return this.api.put(this.getCurrentClientBaseUrl() + '/projects/' + projectId + '/', parameters)
  }

  async updatePartialProject(userId: string, projectId: string, parameters: any): Promise<void> {
    return this.api.patch(this.getBaseUrl() + '/users/' + userId + '/projects/' + projectId + '/', parameters)
  }

  async deleteProject(projectId: string): Promise<void> {
    return this.api.delete(this.getCurrentClientBaseUrl() + '/projects/' + projectId + '/')
  }

  async getProjectNotification(projectId: string, userId: string): Promise<boolean> {
    try {
      const result = await this.api.get<boolean>(
        `api/v1/project_user_subscription/projects/${projectId}/users/${userId}`,
      )
      return result
    } catch (e) {
      return false
    }
  }

  async postProjectNotification(projectId: string, userId: string): Promise<void> {
    try {
      const result = await this.api.post('api/v1/project_user_subscription', {
        user: userId,
        userProject: projectId,
      })
      return result
    } catch (e) {
      console.error(e)
    }
  }

  async deleteProjectNotification(projectId: string, userId: string): Promise<void> {
    try {
      const result = await this.api.delete(`api/v1/project_user_subscription/projects/${projectId}/users/${userId}`)
      return result
    } catch (e) {
      console.error(e)
    }
  }

  async moveProjectToClient(projectId: string, clientId: string): Promise<void> {
    try {
      const result = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + projectId + '/move_to_client/',
        {
          newClientId: clientId,
        },
      )
      return result
    } catch (e) {
      console.error(e)
    }
  }

  async getUserSubProjects(): Promise<void> {
    return await this.api.get(
      this.getCurrentEnvironmentBaseUrl() + '/user_subprojects')
  }

  async getSubProjectPersonalModels(): Promise<void> {
    return await this.api.get(this.getBaseUrl() + '/legacy_project_personal_models/get_personal_models')
  }

  async addSubProjectToPersonalModels(legacyProjectId) {
    try {
      const personalModel = await this.api.post(
        this.getBaseUrl() + '/legacy_project_personal_models/add_to_personal_models/',
        {
          legacyProjectId: legacyProjectId,
        },
      )
      return personalModel
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async removeSubProjectFromPersonalModels(legacyProjectId) {
    try {
      const personalModel = await this.api.post(
        this.getBaseUrl() + '/legacy_project_personal_models/remove_from_personal_models/',
        {
          legacyProjectId: legacyProjectId,
        },
      )
      return personalModel
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async setProjectPassword(userProjectId, adminUserId, password) {
    try {
      const result = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/set_password/',
        {
          userProjectId: userProjectId,
          adminUserId: adminUserId,
          password: password,
        },
      )
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async removeProjectPassword(userProjectId) {
    try {
      const result = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/remove_password/',
        {},
      )
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  ////////////////////////////
  // Project catalog
  ////////////////////////////
  async getProjectCatalog(): Promise<void> {
    return await this.api.get(this.getBaseUrl() + '/project_catalog/')
  }

  async getNoCodeProjectCatalog(): Promise<void> {
    return await this.api.get(this.getBaseUrl() + '/legacy/projects_models')
  }

  async getPlatformProjectCatalog(): Promise<void> {
    return await this.api.get(this.getBaseUrl() + '/platform_project_catalog/1')
  }

  async updatePlatformProjectCatalog(catalog): Promise<void> {
    return await this.api.put(this.getBaseUrl() + '/platform_project_catalog/1/', {
      catalogJson: catalog,
    })
  }

  async getEnvironmentProjectCatalog(environmentPkId): Promise<void> {
    return await this.api.get(this.getBaseUrl() + '/platform_project_catalog/' + environmentPkId)
  }

  async updateEnvironmentProjectCatalog(environmentPkId, catalog): Promise<void> {
    return await this.api.put(this.getBaseUrl() + '/platform_project_catalog/' + environmentPkId + '/', {
      catalogJson: catalog,
    })
  }

  async getEnvironmentDocumentCatalog(environmentPkId): Promise<void> {
    return await this.api.get(this.getBaseUrl() + '/environment_document_catalog/' + environmentPkId)
  }

  async updateEnvironmentDocumentCatalog(environmentPkId, catalog): Promise<void> {
    return await this.api.put(this.getBaseUrl() + '/environment_document_catalog/' + environmentPkId + '/', {
      catalogJson: catalog,
    })
  }

  ////////////////////////////
  // Visio
  ////////////////////////////
  async createVisioMeeting(parameters: any): Promise<void> {
    return this.api.post('api/v1/visio/create_google_meet_event', parameters)
  }

  async activateOutlookCalendarIntegration(): Promise<void> {
    return this.api.get('api/v1/meetings/calendar_integrations/start_microsoft_calendar_authorization_process')
  }

  ////////////////////////////
  // Project
  ////////////////////////////
  async getProjectDetails(projectId: string): Promise<Project> {
    try {
      // const id = uuidValidate(projectId)
      //   ? projectId
      //   : encoder.decode(projectId);
      // const response = await axios.get<Project>("/projects/subsections");
      const id = projectId
      const response = await this.api.get<Project>(this.getCurrentClientBaseUrl() + '/projects/' + id + '/')
      return response
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getClientProjectDetails(projectId: string, clientId: string): Promise<Project> {
    try {
      const response = await this.api.get<Project>(
        this.getCurrentEnvironmentBaseUrl() + '/clients/' + clientId + '/projects/' + projectId + '/',
      )
      return response
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getUsersWithProjectPermissions(projectId: string): Promise<Array<User>> {
    try {
      const response = await this.getUsersWithObjectPermissions(projectId, 'UserProject')
      return response
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getGroupsWithProjectPermissions(projectId: string): Promise<Array<User>> {
    try {
      const response = await this.getGroupsWithObjectPermissions(projectId, 'UserProject')
      return response
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async putProjectTask(projectId: string, taskId: string, params: any): Promise<any> {
    try {
      const response = await this.api.put<any>(
        this.getCurrentClientBaseUrl() + '/projects/' + projectId + '/tasks/' + taskId + '/',
        params,
      )
      return response
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getDocumentGenerationTaskDocuments(taskId: string) {
    try {
      const response = await this.api.get<any>(`api/v1/legacy/tasks/${taskId}/documents/`)
      return response.documents
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getDocumentTemplateHtml(surveyId: string) {
    try {
      const response = await this.api.get<any>(`api/v1/legacy/surveys/${surveyId}/get_html_content`)
      return response
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getUploadTaskFiles(taskId: string) {
    try {
      const response = await this.api.get<any>(`api/v1/legacy/tasks/${taskId}/files/`)
      return response.files
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async downloadUploadTaskFile(taskId: string, fileId: string) {
    try {
      const document = await this.api.getBlob(`api/v1/legacy/tasks/${taskId}/files/${fileId}/download/fake_filename`, {
        responseType: 'blob',
      })
      return document
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async moveUploadTaskFile(originTaskId: string, destinationTaskId: string, fileId: string) {
    try {
      await this.api.get(`api/v1/legacy/tasks/${originTaskId}/files/${fileId}/move/${destinationTaskId}`)
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async deleteUploadTaskFile(taskId: string, fileId: string) {
    try {
      await this.api.get(`api/v1/legacy/tasks/${taskId}/files/${fileId}/delete`)
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async moveUploadTaskFileToProjectDocuments(projectId: string, taskId: string, fileId: string, fileName: string) {
    const authenticatedUser = this.api.getAuthenticatedUser()

    try {
      await this.api.post(`api/v1/legacy/tasks/${taskId}/files/${fileId}/move_to_project_documents/${projectId}`, {
        environmentId: authenticatedUser.environment.id,
        clientId: authenticatedUser.clientId,
        fileName: fileName,
      })
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async renameUploadTaskFile(taskId: string, fileId: string, newFileName: string) {
    try {
      await this.api.post(`api/v1/legacy/tasks/${taskId}/files/${fileId}/rename`, {
        newFileName: newFileName,
      })
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async deleteTaskDocument(documentId: string) {
    try {
      await this.api.delete(`api/v1/legacy/documents/${documentId}`)
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async renameTaskDocument(documentId: string, name: string) {
    try {
      await this.api.put(`api/v1/legacy/documents/${documentId}`, { name })
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async downloadTaskDocument(documentId: string) {
    try {
      const document = await this.api.getBlob(`api/v1/legacy/documents/${documentId}/download/fake_filename`, {
        responseType: 'blob',
      })
      return document
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async downloadSignedDocument(documentId: string) {
    try {
      const document = await this.api.getBlob(`api/v1/legacy/service_files/${documentId}/download`, {
        responseType: 'blob',
      })
      return document
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async deleteSignedDocument(documentId: string) {
    try {
      const document = await this.api.getBlob(`api/v1/legacy/signed_documents/${documentId}/delete`, {
        responseType: 'blob',
      })
      return document
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  // Uploads a document on a generation task
  async uploadTaskDocument(documentId: string, file) {
    try {
      const formData = new FormData()
      formData.append('file', file)

      await this.api.postDocument(`api/v1/legacy/documents/${documentId}/upload`, formData)
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  // Uploads documents on an upload task
  async uploadTaskUploadFiles(projectId: string, taskId: string, files) {
    try {
      const formData = new FormData()

      files.forEach((file) => {
        formData.append('files', file)
      })

      await this.api.postDocument(`api/v1/legacy/projects/${projectId}/tasks/${taskId}/files/upload`, formData)
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async createDocument(name, taskId) {
    try {
      const result = await this.api.post(`api/v1/legacy/documents/`, {
        name,
        legacyTaskId: taskId,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async generateDocument(documentId, clientId, projectId, taskId) {
    try {
      const result = await this.api.post('api/v1/legacy/documents/' + documentId + '/generate', {
        client: clientId,
        project: projectId,
        task: taskId,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async startSignatureProcess(taskId, documents) {
    let testMode = true
    if (import.meta.env.MODE === 'production') {
      testMode = false
    }

    try {
      const result = await this.api.post('api/v1/legacy/documents/' + taskId + '/start_signature_process', {
        documents: documents,
        testMode: testMode,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getDocumentsSignatureProcess(taskId) {
    try {
      const result = await this.api.get('api/v1/legacy/documents/' + taskId + '/start_signature_process')
      return result.results
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getDocumentsSignature(taskId) {
    try {
      const result = await this.api.get('api/v1/legacy/documents/' + taskId + '/signature')
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getTaskSignatureEnvelopes(taskId) {
    try {
      const result = await this.api.get('api/v1/legacy/documents/' + taskId + '/signature_envelopes')
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getSurvey(documentId): Promise<Survey> {
    try {
      const survey = await this.api.get<Survey>(`api/v1/legacy/surveys/${documentId}`)
      survey.pages = survey.pages.filter((page) => page.questions.length > 0)
      return survey
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async putSurvey(documentId, document) {
    try {
      const survey = await this.api.put(`api/v1/legacy/surveys/${documentId}`, document)
      return survey
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  ////////////////////////////
  // Database
  ////////////////////////////

  async getDatabase(): Promise<Database> {
    try {
      const database = await this.api.get<Database>(this.getCurrentClientBaseUrl() + '/database')
      if (database.databaseJson != null) {
        database.databaseJson.clientName = database.clientName
      }
      return database.databaseJson
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getDatabaseEntities(): Promise<Database> {
    try {
      const result = await this.api.get(this.getBaseUrl() + '/database_entities/')
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async createDatabaseEntity(name, definitionJson): Promise<Database> {
    console.log('updating', name, definitionJson)
    try {
      const result = await this.api.post(this.getBaseUrl() + '/database_entities/', {
        name: name,
        definitionJson: definitionJson,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async renameDatabaseEntity(entityId, name): Promise<Database> {
    console.log('updating', entityId, name)
    try {
      const result = await this.api.put(this.getBaseUrl() + '/database_entities/' + entityId + '/', {
        name: name,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async updateDatabaseEntity(entityId, name, definitionJson): Promise<Database> {
    console.log('updating', entityId, name, definitionJson)
    try {
      const result = await this.api.put(this.getBaseUrl() + '/database_entities/' + entityId + '/', {
        name: name,
        definitionJson: definitionJson,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async deleteDatabaseEntity(entityId): Promise<Database> {
    console.log('updating', entityId, name)
    try {
      const result = await this.api.delete(this.getBaseUrl() + '/database_entities/' + entityId + '/')
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async updateDatabase(database: Database, clientId: string): Promise<void> {
    let baseEndpointUrl
    if (clientId == undefined) {
      baseEndpointUrl = this.getCurrentClientBaseUrl()
    } else {
      baseEndpointUrl = this.getCurrentEnvironmentBaseUrl() + '/clients/' + clientId
    }

    try {
      const result = await this.api.post(baseEndpointUrl + '/database', {
        clientId: '42',
        databaseJson: database,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getEnvironmentDatabase(): Promise<Array<Database>> {
    try {
      const database = await this.api.get<Database>(this.getCurrentEnvironmentBaseUrl() + '/database')
      return database
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async downloadDatabaseFile(fileId: string) {
    try {
      const document = await this.api.getBlob(
        this.getCurrentClientBaseUrl() + `/database/files/${fileId}/download/fake_filename`,
        {
          responseType: 'blob',
        },
      )
      return document
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async uploadDatabaseFile(file) {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const result = await this.api.postDocument(this.getCurrentClientBaseUrl() + `/database/files/upload`, formData)

      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async databaseCopyNodeToClient(sourceClientId, destinationClientId, nodeId, parentNodeId) {
    try {
      console.log('copy database node', sourceClientId, destinationClientId, nodeId, parentNodeId)
      const result = await this.api.post(this.getCurrentClientBaseUrl() + '/database/copy_node_to_client', {
        destinationClientId: destinationClientId,
        nodeId: nodeId,
        parentNodeId: parentNodeId,
      })

      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async parseEdiFile(file) {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const result = await this.api.postDocument(`api/v1/edi/parse_file`, formData)
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  ////////////////////////////
  // Application
  ////////////////////////////

  async checkVersion() {
    return axios
      .get(`/index.html?timestamp=${new Date().getTime()}`)
      .then(function (response) {
        let text = response.data
        let r = /<script.*\/(assets[\/]index.*\.js).*$/gim.exec(text)
        if (!r || r.length < 2) {
          return
        }
        let remoteMainScript = r.length > 1 ? r[1] : null
        let localMainScript = null
        let scripts = document.head.getElementsByTagName('script')
        for (let script of scripts) {
          let rl = /(assets[\/]index.*\.js).*$/gim.exec(script.src)
          if (!rl || rl.length < 2) {
            continue
          }
          localMainScript = rl[1]
          break
        }
        if (localMainScript === null) {
          return
        }
        console.log('remoteMainScript', remoteMainScript, 'localMainScript', localMainScript)
        return remoteMainScript !== localMainScript
      })
      .catch(function (error) {
        console.error('error', error)
      })
  }

  async logLocalStorage() {
    const userJsonString = localStorage.getItem('user')
    let user
    if (userJsonString == undefined) {
      user = {}
    } else {
      user = JSON.parse(userJsonString)
    }
    return this.api
      .post(this.getBaseUrl() + `/tracing/local_storage_watcher/`, {
        dataJson: user,
      })
      .then(function (response) {})
      .catch(function (error) {
        console.error('error', error)
      })
  }

  ////////////////////////////
  // Sending Mail
  ////////////////////////////

  async sendMail(taskId, mail, document) {
    try {
      const formData = new FormData()
      formData.append('mail', JSON.stringify(mail))
      // formData.append("taskId", taskId);
      formData.append('document', document)
      const result = await this.api.postDocument(`api/v1/tasks/${taskId}/mails/`, formData)
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getTaskMails(taskId): Promise<Array<Mail>> {
    try {
      const result = await this.api.get<Array<Mail>>(`api/v1/tasks/${taskId}/mails/`)
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  ////////////////////////////
  // V2
  ////////////////////////////

  async v2GenerateDocument(documentId, clientId, projectId, taskId) {
    try {
      const result = await this.api.post('api/v2/documents/' + documentId + '/generate', {
        client: clientId,
        project: projectId,
        task: taskId,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async convertDocxToPdf(docId) {
    try {
      const result = await this.api.getBlob(`api/v2/tasks/converttopdf/${docId}`, {
        responseType: 'blob',
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async convertGeneratedFileToPdf(docId) {
    try {
      const result = await this.api.getBlob(`api/v2/tasks/convert_generated_file_to_pdf/${docId}`, {
        responseType: 'blob',
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  ////////////////////////////
  // Rights management
  ////////////////////////////
  async getTasksPermissionsForUser(tasksIds, userId) {
    try {
      const result = await this.api.post('api/v1/rights_management/get_tasks_permissions_for_user', {
        tasks: tasksIds,
        userId: userId,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getTasksPermissionsForUserWithoutGroups(tasksIds, userId) {
    try {
      const result = await this.api.post('api/v1/rights_management/get_tasks_permissions_for_user_without_groups', {
        tasks: tasksIds,
        userId: userId,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getSectionsPermissionsForUser(sectionsIds, userId) {
    try {
      const result = await this.api.post('api/v1/rights_management/get_sections_permissions_for_user', {
        sections: sectionsIds,
        userId: userId,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getSectionsPermissionsForUserWithoutGroups(sectionsIds, userId) {
    try {
      const result = await this.api.post('api/v1/rights_management/get_sections_permissions_for_user_without_groups', {
        sections: sectionsIds,
        userId: userId,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getTasksPermissionsForGroup(tasksIds, groupId) {
    try {
      const result = await this.api.post('api/v1/rights_management/get_tasks_permissions_for_group', {
        tasks: tasksIds,
        groupId: groupId,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getSectionsPermissionsForGroup(sectionsIds, groupId) {
    try {
      const result = await this.api.post('api/v1/rights_management/get_sections_permissions_for_group', {
        sections: sectionsIds,
        groupId: groupId,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async updateSectionPermissions(
    sectionId,
    userIds,
    usersAddPermissions,
    usersRemovePermissions,
    groupIds,
    groupsAddPermissions,
    groupsRemovePermissions,
  ) {
    try {
      const result = await this.api.post('api/v1/rights_management/update_section_permissions', {
        section_id: sectionId,
        users: userIds,
        usersAddPermissions: usersAddPermissions,
        usersRemovePermissions: usersRemovePermissions,
        groups: groupIds,
        groupsAddPermissions: groupsAddPermissions,
        groupsRemovePermissions: groupsRemovePermissions,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async updateTaskPermissions(
    taskId,
    userIds,
    usersAddPermissions,
    usersRemovePermissions,
    groupIds,
    groupsAddPermissions,
    groupsRemovePermissions,
  ) {
    try {
      const result = await this.api.post('api/v1/rights_management/update_task_permissions', {
        task_id: taskId,
        users: userIds,
        usersAddPermissions: usersAddPermissions,
        usersRemovePermissions: usersRemovePermissions,
        groups: groupIds,
        groupsAddPermissions: groupsAddPermissions,
        groupsRemovePermissions: groupsRemovePermissions,
      })
      return result
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  ////////////////////////////
  // LOP
  ////////////////////////////

  async lopSearchMatter(searchString) {
    return this.api.post('api/v1/lop/matters/search', {
      searchString: searchString,
    })
  }

  async getUserLopCredentialsStatus(userId) {
    return this.api.get('api/v1/lop/credentials/user/' + userId)
  }

  async setUserLopCredentials(userId, login, password) {
    return this.api.post('api/v1/lop/credentials/user/' + userId, {
      login: login,
      password: password,
    })
  }

  ////////////////////////////
  // Document tree
  ////////////////////////////

  async getUserProjectDocumentTree(userProjectId) {
    try {
      const documentTree = await this.api.get(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree',
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getUserProjectDocumentTreeDirectories(userProjectId) {
    try {
      const documentTree = await this.api.get(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/directories',
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }
  async updateUserProjectDocumentTree(userProjectId, documentTree) {}

  async createDocumentTreeDirectory(userProjectId, data) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/nodes/create_directory',
        data,
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async createDocumentTreeFileWithTemplate(userProjectId, data) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/create_file_with_template',
        data,
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async createDocumentTreeFilesWithBasicModels(userProjectId, data) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/create_files_with_basic_models',
        data,
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async deleteDocumentTreeNode(userProjectId, documentTreeNodeId) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/' +
          documentTreeNodeId +
          '/delete_node',
        {},
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async deleteDocumentTreeNodes(userProjectId, nodes) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/delete_nodes',
        {
          nodes: nodes,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async renameDocumentTreeNode(userProjectId, documentTreeNodeId, newName) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/' +
          documentTreeNodeId +
          '/rename_node',
        {
          name: newName,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async renameDocumentTreeNodeVersion(userProjectId, documentTreeNodeId, versionId, newName) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/' +
          documentTreeNodeId +
          '/versions/' +
          versionId +
          '/rename',
        {
          name: newName,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async lockDocumentTreeNode(userProjectId, documentTreeNodeId, newName) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/' +
          documentTreeNodeId +
          '/lock',
        {},
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async unlockDocumentTreeNode(userProjectId, documentTreeNodeId) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/' +
          documentTreeNodeId +
          '/unlock',
        {},
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getDocumentTreeNode(userProjectId, documentTreeNodeId) {
    try {
      const documentTree = await this.api.get(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/nodes/' + documentTreeNodeId,
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async moveDocumentTreeNode(userProjectId, documentTreeNodeId, newParentId) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/' +
          documentTreeNodeId +
          '/move_node',
        {
          parentId: newParentId,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async moveDocumentTreeNodes(userProjectId, nodes, newParentId) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/move_nodes',
        {
          parentId: newParentId,
          nodes: nodes,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async moveDocumentTreeDocumentToUploadTask(userProjectId, documentTreeNodeId, taskId) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/' +
          documentTreeNodeId +
          '/move_node_to_upload_task',
        {
          taskId: taskId,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async moveDocumentTreeDocumentsToUploadTask(userProjectId, nodes, taskId) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/move_nodes_to_upload_task',
        {
          taskId: taskId,
          nodes: nodes,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async copyDocumentTreeDocumentsToUploadTask(userProjectId, nodes, taskId) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/copy_nodes_to_upload_task',
        {
          taskId: taskId,
          nodes: nodes,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async moveDocumentTreeDocumentsToProject(userProjectId, nodes, destinationProjectId) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/move_nodes_to_project',
        {
          destinationProjectId: destinationProjectId,
          nodes: nodes,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async copyDocumentTreeDocumentsToProject(userProjectId, nodes, destinationProjectId) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/copy_nodes_to_project',
        {
          destinationProjectId: destinationProjectId,
          nodes: nodes,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async uploadDocumentTreeFiles(userProjectId, files, parentId, onUploadProgress) {
    try {
      const formData = new FormData()

      files.forEach((file) => {
        formData.append('files', file)
      })

      const response = await this.api.postDocumentWithProgress(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/upload_files/' + parentId,
        formData,
        onUploadProgress,
      )
      console.log('Uploaded files', response)
      return response.nodeIds
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async downloadDocumentTreeFile(userProjectId, documentTreeNodeId) {
    try {
      const document = await this.api.getBlob(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/' +
          documentTreeNodeId +
          '/download_file/fake_filename',
        {
          responseType: 'blob',
        },
      )
      return document
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async downloadDocumentTreeFilesAsZip(userProjectId, nodes) {
    try {
      const document = await this.api.getBlobAsPost(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/download_files_as_zip',
        {
          nodes: nodes,
        },
      )
      return document
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async downloadDocumentTreeFileVersion(userProjectId, documentTreeNodeId, versionId) {
    try {
      const document = await this.api.getBlob(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/nodes/' +
          documentTreeNodeId +
          '/versions/' +
          versionId +
          '/download_file/fake_filename',
        {
          responseType: 'blob',
        },
      )
      return document
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async documentTreeImportLopMatter(userProjectId, lopMatterId) {
    try {
      const documentTree = await this.api.get(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          userProjectId +
          '/document_tree/lop/import_matter/' +
          lopMatterId,
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async exportProjectToDocuments(completeProjectId, completeProjectName, subProjectId, subProjectName, projectNodes) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() +
          '/projects/' +
          completeProjectId +
          '/document_tree/export_project_to_documents',
        {
          completeProjectName: completeProjectName,
          subProjectId: subProjectId,
          subProjectName: subProjectName,
          nodes: projectNodes,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getDocumentTreePersonalModels(withSubTree): Promise<void> {
    return await this.api.get(
      this.getBaseUrl() + '/document_tree_node_personal_models/get_personal_models?withSubTree=' + withSubTree,
    )
  }

  async getDocumentTreePersonalModelsWithSubTrees(): Promise<void> {
    return await this.api.get(
      this.getBaseUrl() + '/document_tree_node_personal_models/get_personal_models_with_sub_trees',
    )
  }

  async addDocumentTreeNodeToPersonalModels(documentTreeNodeId) {
    try {
      const personalModel = await this.api.post(
        this.getBaseUrl() + '/document_tree_node_personal_models/add_to_personal_models/',
        {
          documentTreeNodeId: documentTreeNodeId,
        },
      )
      return personalModel
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async removeDocumentTreeNodeFromPersonalModels(documentTreeNodeId) {
    try {
      const personalModel = await this.api.post(
        this.getBaseUrl() + '/document_tree_node_personal_models/remove_from_personal_models/',
        {
          documentTreeNodeId: documentTreeNodeId,
        },
      )
      return personalModel
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async duplicateDocumentTreeNodes(userProjectId, nodeIds) {
    try {
      const documentTree = await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/duplicate_nodes',
        {
          nodes: nodeIds,
        },
      )
      return documentTree
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  /**
   * Single file extraction
   * @param userProjectId Id of current project
   * @param nodeIds Id of nodes to extract data from
   * @returns
   */
  async fileExtract(userProjectId, nodeIds, model) {
    try {
      return await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/file_extract',
        {
          nodeIds: nodeIds,
          model: model,
        },
      )
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  /**
   * Batch AI job to classify & extract several documents
   * @param userProjectId Id of current project
   * @param nodeIds Id of nodes to treat
   * @returns
   */
  async batchTreatment(userProjectId, nodeIds, doClassify, doExtract) {
    try {
      return await this.api.post(
        this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/document_tree/batch_job',
        {
          nodeIds: nodeIds,
          doClassify: doClassify,
          doExtract: doExtract,
        },
      )
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  ////////////////////////////
  // Event emails
  ////////////////////////////
  async sendNewChatMessageEmail(userProjectId, taskId, documentId, recipients, messageContent) {
    return await this.api.post(
      this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/events/send_chat_email/',
      {
        taskId: taskId,
        documentId: documentId,
        messageContent: messageContent,
        includeTaskLink: true,
        recipients: recipients,
      },
    )
  }

  async sendNotificationEmail(userProjectId, taskId, recipients, messageContent, eventsContent) {
    return await this.api.post(
      this.getCurrentClientBaseUrl() + '/projects/' + userProjectId + '/events/send_notification_email/',
      {
        taskId: taskId,
        messageContent: messageContent,
        recipients: recipients,
        eventsContent: eventsContent,
      },
    )
  }

  ////////////////////////////
  // Search
  ////////////////////////////

  async search(searchString) {
    return this.api.post('api/v1/search', {
      query: searchString,
    })
  }

  async searchProjects(searchString) {
    return this.api.post('api/v1/search_projects', {
      query: searchString,
    })
  }

  ////////////////////////////
  // Registers
  ////////////////////////////

  async getRequestRegister(projectId) {
    return this.api.post('api/v1/registers/get_gdpr_requests_register', {
      gdpr_project_id: projectId,
    })
  }

  async getViolationRegister(projectId) {
    return this.api.post('api/v1/registers/get_gdpr_violations_register', {
      gdpr_project_id: projectId,
    })
  }

  async getSubcontractorRegister(projectId) {
    return this.api.post('api/v1/registers/get_gdpr_sub_contractors_register', {
      gdpr_project_id: projectId,
    })
  }

  async getTreatmentRegister(projectId) {
    return this.api.post('api/v1/registers/get_gdpr_treatments_register', {
      gdpr_project_id: projectId,
    })
  }

  async getAllTreatmentRegister(projectId) {
    return this.api.post('api/v1/registers/get_gdpr_all_treatments_register', {
      gdpr_project_id: projectId,
    })
  }

  ////////////////////////////
  // Outlook
  ////////////////////////////

  async uploadDocumentTreeEml(
    userClientId,
    userProjectId,
    files,
    filenames,
    parentId,
    outlookItemId,
    onUploadProgress,
  ) {
    try {
      const formData = new FormData()

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i], filenames[i])
      }

      console.log('outlookItemId', outlookItemId)
      formData.append('outlook_item_id', outlookItemId)

      await this.api.postDocumentWithProgress(
        this.getCurrentEnvironmentBaseUrl() +
          '/clients/' +
          userClientId +
          '/projects/' +
          userProjectId +
          '/document_tree/upload_eml/' +
          parentId,
        formData,
        onUploadProgress,
      )
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async retrieveDocumentTreeEml(
    userClientId,
    userProjectId,

    parentId,
    outlookCallbackToken,
    outlookEwsUrl,
    outlookItemId,
    documentFilename,
  ) {
    try {
      await this.api.post(
        this.getCurrentEnvironmentBaseUrl() +
          '/clients/' +
          userClientId +
          '/projects/' +
          userProjectId +
          '/document_tree/retrieve_eml/' +
          parentId,
        {
          outlookCallbackToken: outlookCallbackToken,
          outlookEwsUrl: outlookEwsUrl,
          outlookItemId: outlookItemId,
          documentFilename: documentFilename,
        },
      )
    } catch (error) {
      console.error('ERROR', error)
      throw error
    }
  }

  async getOutlookMessageImmportInfo(itemId) {
    return this.api.post('api/v1/outlook/message', {
      outlook_item_id: itemId,
    })
  }

  ////////////////////////////
  // Favorites
  ////////////////////////////

  async getClientForProject(projectId: string): Promise<void> {
    return await this.api.post(this.getBaseUrl() + '/favorites/get_project_client/', {
      user_project_id: projectId,
    })
  }

  async getFavorites(): Promise<void> {
    return await this.api.get(this.getBaseUrl() + '/favorites/get_favorites/')
  }

  async setFavorite(data): Promise<void> {
    return await this.api.post(this.getBaseUrl() + '/favorites/set_favorite/', data)
  }

  async unsetFavorite(data): Promise<void> {
    return await this.api.post(this.getBaseUrl() + '/favorites/unset_favorite/', data)
  }
}

export default UserApi
