<script lang="ts" setup>
import { ref, Ref, inject } from 'vue'
import { Dialog, useQuasar } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { ProjectAccountableOptionsKey, ProjectKey } from '@/models/symbols'
import { UserAccountableOptions } from '@/models/projects/UserAccountableOptions'
import AddSubProjectTreeDialog from '@/components/projects/dialog/project_parameters/AddSubProjectsTreeDialog.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

const emit = defineEmits(['toggleCollapse', 'toggleOnlyShowDocuments', 'toggleOnlyDeadline', 'projectNameClicked'])

const $q = useQuasar()

const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()

const project = inject(ProjectKey)
const accountableOptions = inject(ProjectAccountableOptionsKey)
const usersOptions: Ref<Array<UserAccountableOptions>> = ref([])
const usersFilter: Ref<Array<any>> = inject('usersFilter', ref([]))
const progressionsOptions = [
  { value: 'not_started', label: 'Nouveau' },
  { value: 'started', label: 'En cours' },
  { value: 'finished', label: 'Terminé' },
]
const progressionsFilter = inject('progressionsFilter', ref([]))
const isOnlyDocument = ref(false)
const isOpenAllSections = ref(false)
const isDeadlineFilter = ref(false)

if (accountableOptions) {
  accountableOptions.value.map((option) => {
    usersOptions.value.push({ value: option, label: option.fullName })
  })
}

function onDocumentFilterClick() {
  isOnlyDocument.value = !isOnlyDocument.value
  emit('toggleOnlyShowDocuments', isOnlyDocument.value)
}
function onDeadlineFilterClick() {
  isDeadlineFilter.value = !isDeadlineFilter.value
  emit('toggleOnlyDeadline', isDeadlineFilter.value)
}

function onToggleCollapse() {
  isOpenAllSections.value = !isOpenAllSections.value
  emit('toggleCollapse', !isOpenAllSections.value)
}

const progressFilterRef = ref(null)
const progressFilterLabel = ref('Progression')

function onProgressFilterChange(value) {
  console.log('onProgressFilterChange', value)
  if (value == null || value.length == 0) {
    progressFilterLabel.value = 'Progression'
  } else {
    progressFilterLabel.value = undefined
  }
}

const usersFilterLabel = ref('Utilisateurs')

function onUserFilterChange(value) {
  console.log('onUserFilterChange', value)
  if (value == null || value.length == 0) {
    usersFilterLabel.value = 'Utilisateurs'
  } else {
    usersFilterLabel.value = undefined
  }
}

function addSubProject() {
  console.log('addSubProject')
  showAddSubProjectDialog()
}

// Begin adapted duplicate from SubProjectsParameters.vue
function showAddSubProjectDialog() {
  const dialog = Dialog.create({
    component: AddSubProjectTreeDialog,
    componentProps: {},
  })
    .onOk((subProjectTemplateIds) => {
      dialog.hide()
      createSubProjects(subProjectTemplateIds)
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function createSubProjects(subProjectTemplateIds) {
  $q.loading.show({
    message: "Nous préparons votre dossier.\nMerci de patienter, cela peut prendre jusqu'à 1 minute.",
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
  })
  userApi
    .createSubProjects({
      userProjectId: project.value.id,
      projectTemplateIds: subProjectTemplateIds.split(','),
    })
    .then((project) => {
      $q.loading.hide()
      window.history.go()
    })
    .catch((e) => {
      $q.loading.hide()
    })
}
</script>
<template>
  <div class="items-center sticky-content" style="">
    <!-- q-px-md q-pb-sm q-gutter-sm -->
    <div class="row items-center"
      style="font-size: 19px; font-weight: 700; margin-left: 8px; padding-bottom: 8px; padding-top: 8px">
      <span class="" style="color: rgb(64, 104, 200)">Opérations juridiques</span>
    </div>

    <div class="row items-center no-wrap" style="margin-left: 8px; padding-bottom: 8px">

      <q-btn dense round flat icon="o_add" @click="addSubProject">
        <q-tooltip class="tooltip">Ajouter des opérations juridiques</q-tooltip>
      </q-btn>

      <q-btn dense round flat :icon="!isOpenAllSections ? 'unfold_more' : 'unfold_less'" @click="onToggleCollapse">
        <q-tooltip class="tooltip">{{
        isOpenAllSections ? 'Fermer toutes les sections' : 'Ouvrir toutes les sections'
      }}</q-tooltip>
      </q-btn>

      <q-btn dense round flat icon="attach_file" :color="isOnlyDocument ? 'primary' : 'black'"
        @click="onDocumentFilterClick">
        <q-tooltip class="tooltip">{{
        isOnlyDocument ? 'Montrer toutes les tâches' : 'Montrer uniquement les tâches contenant un document'
      }}</q-tooltip>
      </q-btn>

      <q-btn dense round flat icon="event" :color="isDeadlineFilter ? 'primary' : 'black'"
        @click="onDeadlineFilterClick">
        <q-tooltip class="tooltip">{{
        isDeadlineFilter ? 'Montrer toutes les tâches' : 'Montrer uniquement les tâches avec échéance'
      }}</q-tooltip>
      </q-btn>

      <q-select rounded outlined dense options-dense :stack-label="false" multiple clearable :label="usersFilterLabel"
        v-model="usersFilter" class="filter-select progress-select" :options="usersOptions"
        @update:model-value="onUserFilterChange">
      </q-select>
      <q-select rounded outlined dense options-dense multiple clearable :label="progressFilterLabel"
        class="filter-select progress-select" v-model="progressionsFilter" :options="progressionsOptions"
        style="margin-left: 10px" @update:model-value="onProgressFilterChange" ref="progressFilterRef">
        <!-- <template v-slot:selected-item="scope">
          <q-chip removable dense @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="white"
            text-color="" class="q-ma-none">
            <q-avatar color="" text-color="white" :icon="scope.opt.icon" />
            {{ scope.opt.label }}
          </q-chip>
        </template> -->
      </q-select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/app.variables.scss';

.filter-select {
  min-width: 150px;
}

.sticky-content {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 1;
  background-color: $page_background_color;
}

// Progress select
.progress-select {
  margin-left: 6px;
  font-size: 24px;
}

:deep(.progress-select .q-field__control) {
  min-height: 28px;
  height: 28px;
}

:deep(.progress-select .q-field__control-container) {
  margin-top: -6px;
}

:deep(.progress-select .q-field__append) {
  margin-top: -6px;
  padding-left: 0px;
  margin-right: -6px;
}

:deep(.progress-select .q-field__native) {
  font-size: 12px;
  margin-top: 0px;
}
</style>
